import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import App from './components/app'
import reportWebVitals from './report-web-vitals'
import 'focus-visible/dist/focus-visible'

const root = createRoot(document.querySelector('#root') as HTMLElement)
root.render(<App />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log)
