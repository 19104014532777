import type { TypedUseSelectorHook } from 'react-redux'
import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector
} from 'react-redux'
import type { ThunkAction, ThunkDispatch } from 'redux-thunk'
import type { Action } from '@reduxjs/toolkit'
import { configureStore } from '@reduxjs/toolkit'
import { rootReducer } from './root-reducer'

// const preloadedState = loadState();
const preloadedState = {}

const store = configureStore({
  preloadedState,
  reducer: rootReducer,
  devTools: true
})

export const withStore = (initialState: any) => {
  return configureStore({
    preloadedState: initialState,
    reducer: rootReducer,
    devTools: Boolean(localStorage.getItem('reduxDevtools'))
  })
}

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector

export const useDispatch = () =>
  useReduxDispatch<ThunkDispatch<any, any, any>>()

export default store
