import styles from './user-card.module.css'
import { TeamMember } from '../../../../types'
import { UserAvatar } from '../user-avatar'
import { Caption1, Caption2 } from '../../text/text'
import { useStatus } from '../use-status'
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Divider
} from '@chakra-ui/react'
import { Loader } from '../../../loader/loader'
import { useState } from 'react'

export const UserCard = ({ user }: { user: TeamMember }) => {
  const { statusText } = useStatus(user.id)
  const userName = user.profile.first_name ? `${user.profile.first_name} ${user.profile.last_name}` : user.real_name

  return (
    <>
      <div className={styles.card}>
        <div style={{ maxWidth: '50px', margin: 'auto' }}>
          <UserAvatar user={user} isNight={false} cardOpen={true} />
        </div>
        <div>
          <Caption2 style={{ fontWeight: 700 }}>
            {userName}
          </Caption2>
        </div>
        <div
          style={{
            marginTop: '5px',
            marginBottom: '5px'
          }}>
          <Caption2>{user.profile.title}</Caption2>
        </div>
        {statusText.length > 0 && (
          <div
            style={{
              marginTop: '5px',
              marginBottom: '5px',
              background: '#e3e3e3',
              borderRadius: '5px',
              padding: '1px 3px'
            }}>
            <Caption1
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'normal'
              }}>
              {statusText}
            </Caption1>
          </div>
        )}

        <div style={{ marginTop: '10px', marginBottom: '10px' }}>
          <Divider />
        </div>

        <div className={styles.email}>
          <Caption2>
            <a href={`mailto:${user.profile.email}`}>{user.profile.email}</a>
          </Caption2>
        </div>
        <div className="user-phone">
          <Caption2>
            <a href={`tel:${user.profile.phone}`}>{user.profile.phone}</a>
          </Caption2>
        </div>

        <div style={{ marginTop: '10px', marginBottom: '10px' }}>
          <Divider />
        </div>

        <Button size="xs" marginBottom="6px">
          <a href={`slack://user?team=${user.team_id}&amp;id=${user.id}`}>
            View in Slack
          </a>
        </Button>

        <PictureButton user={user} />
      </div>
    </>
  )
}

const PictureButton = ({ user }: { user: TeamMember }) => {
  const [isImageLoading, setIsImageLoading] = useState(true)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const userName = user.profile.first_name ? `${user.profile.first_name} ${user.profile.last_name}` : user.real_name
  
  return (
    <>
      <Button onClick={onOpen} size="xs">
        Enlarge picture
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent  minHeight="496px">
          <ModalHeader>
            {userName}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <>{isImageLoading && <Loader />}</>
            <img
              style={{ position: 'absolute', top: '45px', left: '0', padding:'20px', objectFit: 'contain' }}
              src={user.profile.image_512}
              alt={user.profile.first_name}
              onLoad={() => setIsImageLoading(false)}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
