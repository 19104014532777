// @ts-nocheck

import axios from 'axios'
import { Config } from '../config'
import { Conversation, SlackUser, TeamMember, UserGroup } from '../types'
import { handlePaging } from './paging.service'
import { makeLogger } from '../utils/debug'

const { slack } = Config
const objectToEncoded = (postdata: Record<string, any>) =>
  new URLSearchParams(Object.keys(postdata).map((key) => [key, postdata[key]]))

export const slackApi = axios.create({
  baseURL: window.location.href.includes('localhost') ? 'http://localhost:8080/slack-api' : '/slack-api',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
})

const logger = makeLogger('slack:axios')

slackApi.interceptors.request.use((config) => {
  logger('Sending new request to %s %o', config.url, config)
  if (!config.data) {
    config.data = {}
  }
  
  // Just add token and limit to whatever data is already there
  config.data['token'] = localStorage.getItem('userToken')
  config.data['limit'] = 1000

 
  config.data = objectToEncoded(config.data)
  return config
})

slackApi.interceptors.response.use((response) => {
  if (response.status === 401) {
    SlackService.authoriseWithSlack()
  }
  return response.data
})

const getUserData: () => Promise<SlackUser> = () => 
  slackApi.post('', { endpoint: 'auth.test' })

const getUsers: () => Promise<TeamMember[]> = () =>
  handlePaging<'members', TeamMember>(
    ({ cursor }) => slackApi.post('', { endpoint: 'users.list', cursor }),
    'members'
  ).then((members) =>
    members.filter((x: TeamMember) => {
      const isDeleted = Boolean(x.deleted)
      const isBot = Boolean(x.is_bot)
      const isSlackBot = x.id === 'USLACKBOT'
      return !(isDeleted || isBot || isSlackBot)
    })
  )

const getPrivateChannels = () =>
  handlePaging<'channels', Conversation>(
    ({ cursor }) =>
      slackApi.post('', {
        endpoint: 'conversations.list',
        exclude_members: 'false',
        exclude_archived: 'true',
        types: 'private_channel',
        cursor
      }),
    'channels'
  )

const getPublicChannels = () =>
  handlePaging<'channels', Conversation>(
    ({ cursor }) =>
      slackApi.post('', {
        endpoint: 'conversations.list',
        exclude_members: 'false',
        exclude_archived: 'true',
        types: 'public_channel',
        cursor
      }),
    'channels'
  )

const getChannels = () =>
  Promise.all([getPublicChannels(), getPrivateChannels()]).then(([c1, c2]) => [
    ...c1,
    ...c2
  ])

const getUserGroups: () => Promise<UserGroup[]> = () =>
  handlePaging<'usergroups', UserGroup>(
    ({ cursor }) =>
      slackApi.post('', { endpoint: 'usergroups.list', include_users: true, cursor }),
    'usergroups'
  )

const getStatus: (id: string) => Promise<{ presence: 'away' | 'active' }> = (
  id: string
) => slackApi.post('', { endpoint: 'users.getPresence', user: id })

const getConversationMembers: (id: string) => Promise<string[]> = (
  id: string
) =>
  handlePaging<'members', string>(
    ({ cursor }) =>
      slackApi.post('', { endpoint: 'conversations.members', channel: id, cursor }),
    'members'
  )

const getUserInfo: (id: string) => Promise<TeamMember | null> = async (id: string) => {
  try {
    const response = await slackApi.post('', { endpoint: 'users.info', user: id });
    if (response.ok) {
      return response.user;
    } else {
      logger('getUserInfo Error: API response not ok', response.error);
      return null;
    }
  } catch (error) {
    logger('getUserInfo Exception', error);
    throw error;
  }
}

const getDoNotDisturb = (id: string) =>
  slackApi.post('', { endpoint: 'dnd.info', user: id })
    .then((result: any) => ({
      start: result.dnd_enabled ? result.next_dnd_start_ts * 1000 : undefined,
      end: result.dnd_enabled ? result.next_dnd_end_ts * 1000 : undefined
    }))

const authoriseWithSlack = () => {
  window.location.replace(
    'https://slack.com/oauth/v2/authorize?client_id=' +
      slack.authConfig.client_id +
      '&scope=' +
      slack.authConfig.scope +
      '&user_scope=' +
      slack.authConfig.user_scope +
      '&redirect_uri=' +
      slack.authConfig.redirect_uri
  )
}

export const SlackService = {
  getChannels,
  getUserData,
  getUserGroups,
  getUsers,
  getUserInfo,
  getStatus,
  getConversationMembers,
  getDoNotDisturb,
  authoriseWithSlack
}
