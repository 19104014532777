import { mergeStyles } from '../../../../../utils/merge-styles'
import styles from '../../user-detail/user.module.css'
import { useUserPresence } from './use-user-presence'

export const PresenceDot = ({
  isCompact,
  userId
}: {
  isCompact: boolean
  userId: string
}) => {
  const presence = useUserPresence(userId)

  return (
    <div
      className={mergeStyles(
        isCompact ? styles.compactStatusCenter : styles.statusCenter,
        styles[presence ?? 'loading']
      )}
    />
  )
}
