import { useBaseDate } from '../../../../../hooks/use-base-date'
import { ViewMode } from '../../../../../types'
import { useView } from '../../../../../hooks/use-view'

export const useIsNight = (timezone: string) => {
  const { formatBaseDateToParts } = useBaseDate(timezone)
  const { view } = useView()
  const hour = Number(
    formatBaseDateToParts({
      timeZone: timezone,
      hourCycle: 'h23',
      hour: 'numeric'
    }).find((x) => x.type === 'hour')?.value ?? 0
  )
  return view !== ViewMode.Timeline && (hour >= 22 || hour < 6)
}
