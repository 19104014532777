import { useTeam } from '../../../../../../../hooks'
import { StandardFilter } from './common'

export const ChannelFilter = () => {
  const { channels } = useTeam()

  return (
    <StandardFilter
      items={channels}
      type="channel"
      selectProps={{ placeholder: 'Select #channel' }}
    />
  )
}
