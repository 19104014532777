import React, { Suspense, useEffect } from 'react'
import './app.css'

import ReactGA from 'react-ga'
import { IntlProvider } from 'react-intl'
import { ChakraProvider } from '@chakra-ui/react'
import { AuthenticationProvider } from '../context/authentication.context'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { TermsAndConditions } from './modal/terms-and-conditions'
import { teamTimezoneTheme } from '../theme'
import store from '../store'
import { Provider } from 'react-redux'
import { Config } from '../config'
import { Loader } from './loader/loader'
import MainDocument from './main-document/main-document'

const urlParameters = new URLSearchParams(window.location.search)

if(urlParameters.get('command')) {
  localStorage.setItem('command', 'yes') //Used to render the app for the Slack command
}

if (
  process.env.NODE_ENV === 'development' &&
  !urlParameters.get('userToken') &&
  !localStorage.getItem('userToken')
) {
  console.log('loading mocks')
  // eslint-disable-next-line unicorn/prefer-module
  require('../__mocks__')
  localStorage.setItem('debug', '*')
  localStorage.setItem('reduxDevtools', '1')
}

ReactGA.initialize(Config.analytics.trackingCode)

const App = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  return (
    <IntlProvider locale="en" defaultLocale="en">
      <ChakraProvider theme={teamTimezoneTheme}>
        <Provider store={store}>
          <BrowserRouter>
            <AuthenticationProvider>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100vh'
                }}>
                <TermsAndConditions />
                <Suspense fallback={<Loader />}>
                  <Routes>
                    <Route path="/app/*">
                      <Route index element={<MainDocument />} />
                      <Route path=":view" element={<MainDocument />}>
                        <Route
                          path=":category/:value"
                          element={<MainDocument />}
                        />
                      </Route>
                    </Route>
                    <Route path="*" element={<Navigate to="/app" replace />} />
                  </Routes>
                </Suspense>
              </div>
            </AuthenticationProvider>
          </BrowserRouter>
        </Provider>
      </ChakraProvider>
    </IntlProvider>
  )
}

export default App
