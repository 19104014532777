import { useColumnUtils } from '../../../../main-document/views/timezone/column/use-column-utils'
import styles from '../user.module.css'
import { UserAvatar } from '../../user-avatar'
import { UserCard } from '../../user-card'
import { RefObject, useState } from 'react'
import { TeamMember } from '../../../../../types'

export const RegularUser = ({
  userName,
  user
}: {
  userName: RefObject<HTMLDivElement>
  user: TeamMember
}) => {
  const { isNight } = useColumnUtils()
  const [showDetails, setShowDetails] = useState(false)

  const formattedUserName = user.profile.first_name ? `${user.profile.first_name}` : user.real_name?.split(' ')[0]

  return (
    <div
      data-userid={user.id}
      className={styles.user}
      ref={userName}
      onMouseEnter={() => setShowDetails(true)}
      onMouseLeave={() => setShowDetails(false)}>
      <UserAvatar user={user} isNight={isNight} cardOpen={showDetails} />
      <p className={styles.userName}>{formattedUserName}</p>
      {showDetails && (
        <div className={styles.uc}>
          <UserCard user={user} />
        </div>
      )}
    </div>
  )
}
