import { TeamMember } from '../types'
import { groupBy } from 'lodash'

export const GroupTeamMembersByTZ = (members: TeamMember[]) =>
  Object.values(groupBy(members, (member: TeamMember) => member.tz_offset))
    .map((member) => member as TeamMember[])
    .map((group) => ({
      timezone: group[0].tz,
      offset: group[0].tz_offset,
      continent: group[0].tz?.split('/')[0],
      label: group[0].tz_label,
      members: group,
      priority: group.length
    }))
    .sort((a, b) => {
      // If either offset is undefined, sort to put it at the end
      if (a.offset === undefined) return 1; // a should come after b
      if (b.offset === undefined) return -1; // a should come before b
      // If both offsets are defined, sort by offset ascending
      return a.offset - b.offset;
    });

