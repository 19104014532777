import styles from '../user-detail/user.module.css'
import { PresenceDot } from './presence-dot/presence-dot'

export const PresenceIndicator = ({
  isCompact,
  userId
}: {
  isCompact: boolean
  userId: string
}) => {
  return (
    <div className={isCompact ? styles.compactStatus : styles.status}>
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        <PresenceDot userId={userId} isCompact={isCompact} />
      </div>
    </div>
  )
}
