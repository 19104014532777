import {
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  Stack
} from '@chakra-ui/react'
import { Trigger } from '../common'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { ReactNode } from 'react'

export const Dropdown = ({
  icon,
  children,
  text,
  width
}: {
  icon: (x: any) => ReactNode
  children: any
  text: ReactNode
  width?: string
}) => {
  return (
    <Popover>
{({ isOpen }) => (
      <>
      <Trigger>
        <Button
          px={4}
          py={2}
          size="md"
          transition="background 0.2s"
          borderRadius="md"
          borderWidth="1px"
          bg="white"
          color="grey"
          width="100%"
          _expanded={{ bg: 'var(--chakra-colors-blue-300)', color: 'white' }}
          style={{
            fontSize: '13px',
            display: 'flex',
            verticalAlign: 'middle',
            padding: '5px 8px'
          }}>
          <div
            style={{
              display: 'inline-block',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
            {icon({
              w: 5,
              h: 5,
              style: {
                marginRight: '5px',
                verticalAlign: 'middle',
                marginTop: '-2px'
              }
            })}

            {text}
          </div>
          <div style={{ display: 'inline-block', flex: '1' }} />
          <div style={{ display: 'inline-block' }}>
            <div style={{ display: 'inline-block', marginLeft: 'auto' }}>
              <div
                style={{
                  width: '1px',
                  height: '15px',
                  verticalAlign: 'middle',
                  display: 'inline-block',
                  background: 'rgba(0,0,0,0.1)',
                  margin: '3px'
                }}
              />
              <ChevronDownIcon w={5} h={5} />
            </div>
          </div>
        </Button>
      </Trigger>
      <PopoverContent
      display={isOpen ? "block" : "none"}
        width={width}
        style={{
          background: '#f0f0f0',
          boxShadow:
            '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)'
        }}>
        <PopoverBody>
          <Stack style={{fontSize: '13px'}}>{children}</Stack>
        </PopoverBody>
      </PopoverContent>
        </>
    )}

    </Popover>
  )
}
 