import { Stack } from '@chakra-ui/react'
import { SelectView } from './select-view/select-view'
import { AdvancedOptions } from './advanced-options/advanced-options'


export const ViewOptions = () => {
  return (
    <Stack>
      <SelectView />
      <AdvancedOptions />
    </Stack>
  )
}
