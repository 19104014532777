import { useSelector } from '../store'
import { useUser } from './use-user'
import { memoize } from 'lodash'
import { TeamMember } from '../types'
import { GroupTeamMembersByTZ } from '../utils/group-team-members-by-tz'
import { useMemo } from 'react'

const putMembersIntoZones = memoize((members: TeamMember[]) => {
  return GroupTeamMembersByTZ(members)
})

export const useTeam = () => {
  const { allMembers, members, channels, groups, userGroups, trialLeft } =
    useSelector((state) => state.team)
  const { team: name, team_id: id, url } = useUser()

  const zonedMembers = useMemo(() => putMembersIntoZones(members), [members])

  return {
    name,
    id,
    url,
    members,
    allMembers,
    channels,
    groups,
    userGroups,
    zonedMembers,
    trialLeft
  }
}
