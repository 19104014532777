import { Title2 } from '../../../../shared'
import { useFilterItem } from './use-filter-item'
import { FilterIcon } from './filter-icon/filter-icon'
import styles from './filter-details.module.css'

export const FilterDetails = () => {
  const filter = useFilterItem()

  if (!filter) {
    return null
  }

  return (
    <div className={styles.TextContent}>
      <Title2 as={'span'} fontWeight={500}>
        {' > '}
        <FilterIcon category={filter.category} /> {filter.item.name}
      </Title2>
    </div>
  )
}
