import { InfoBar } from './info-bar'
import { ToolBar } from './toolbar/tool-bar'

export const Header = () => {
  return (
    <>
      <ToolBar />
      <InfoBar />
    </>
  )
}
