import { useMemo } from 'react'
import { useFiltered } from '.'
import { TeamMember } from '../types'
import { useSoftFilter } from './use-soft-filter'

export const useMeetingTime = () => {
  const { zonedMembers } = useFiltered()
  const { members } = useSoftFilter()

  const workingHoursByTimezone: timezoneObject[] = useMemo(
    () => getWorkingHoursByTimezone(zonedMembers),
    [zonedMembers]
  )
  const workingMembersCountByHour = getWorkingMembersCountByHour(
    workingHoursByTimezone
  )
  const maxAvailability = Math.max(
    ...workingMembersCountByHour.map((o) => o.count)
  )

  const maxAvailabilityPercent = maxAvailability / members.length

  const hoursWithMaxAvailability = workingMembersCountByHour.filter(
    (o) => o.count === maxAvailability
  )

  return {
    hoursWithMaxAvailability,
    maxAvailabilityPercent,
    workingHoursByTimezone
  }
}

const getWorkingHoursByTimezone = (zonedMembers: zonedObject[]) =>
  zonedMembers.map((zone) => {
    const startWorkingHour = 9
    const workingHours = 8

    const hourOffset: number = zone.offset / 3600
    const workingHoursArray: Date[] = []
    for (let index = 0; index < workingHours; index++) {
      let workHourUTC = new Date()
      workHourUTC.setUTCHours(startWorkingHour + index - hourOffset, 0, 0, 0)
      workingHoursArray.push(workHourUTC)
    }
    return {
      timezone: zone.timezone,
      hourOffset: hourOffset,
      workingHours: workingHoursArray,
      membersCount: zone.members.length
    }
  })

const getWorkingMembersCountByHour = (
  workingHoursByTimezone: timezoneObject[]
) => {
  const UTCFullDayHours: Date[] = []
  for (let index = 0; index < 24; index++) {
    let hourUTC = new Date()
    hourUTC.setUTCHours(index, 0, 0, 0)
    UTCFullDayHours.push(hourUTC)
  }

  const workingMembersCountByHour = UTCFullDayHours.map((hour: Date) => {
    let hourWorkingMembersCount = 0
    //eslint-disable-next-line unicorn/no-for-loop
    for (let index = 0; index < workingHoursByTimezone.length; index++) {
      if (
        workingHoursByTimezone[index].workingHours
          ?.map((time) => time.toUTCString())
          .includes(hour.toUTCString())
      ) {
        hourWorkingMembersCount += workingHoursByTimezone[index].membersCount
      }
    }

    return {
      hour: hour,
      count: hourWorkingMembersCount
    }
  })

  return workingMembersCountByHour
}

type timezoneObject = {
  timezone: string
  hourOffset: number
  workingHours: Date[]
  membersCount: number
}

type zonedObject = {
  timezone: string
  offset: number
  continent: string
  label: string
  members: TeamMember[]
  priority: number
}
