import { Popover } from "@typeform/embed-react"
import { useState } from "react"
import { useActiveUser } from "./header/toolbar/filters/user-links/use-active-user"

export const FeedbackForm = () => {
    const hasUserAlreadySubmittedFeedback = localStorage.getItem('hasUserAlreadySubmittedFeedback')
    const [isVisible, setIsVisible] = useState(hasUserAlreadySubmittedFeedback !== 'true')
    const activeUser = useActiveUser()
  
    if (!isVisible) {
      return null
    }
  
    return (
      <Popover
        id="Xeb6Be0A"        
        buttonColor="#00C49B"      
        autoClose={1000}
        onSubmit={() => {
          setTimeout(() => {
            localStorage.setItem('hasUserAlreadySubmittedFeedback', 'true')
            setIsVisible(false)
          }, 1000)
        }}
        hidden={{
          name: activeUser?.name ?? 'friend',
          email: activeUser?.profile.email ?? 'friend'
        }}
      />
    )
  }