import { useCallback } from 'react'
import { useDispatch, useSelector } from '../store'
import { SettingActions } from '../store/slices/settings'
import { FilterActions } from '../store/slices/filters'
import { useActiveFilter } from './use-active-filter'

export const useSettings = () => {
  const { hideGuests, onlineOnly } = useActiveFilter()
  const { baseDate, ...settings } = useSelector((state) => state.settings)
  const dispatch = useDispatch()

  const followLiveTime = () => {
    dispatch(SettingActions.setBaseDate(new Date()))
    dispatch(SettingActions.followRealDate(true))
  }

  const setHour = useCallback(
    (time: number, minutes?: number) => {
      dispatch(SettingActions.followRealDate(false))
      const hour = Math.floor(time)
      const newDate = new Date()
      if (!minutes) {
        const mins = time - hour
        newDate.setMinutes(60 * mins)
      } else {
        newDate.setMinutes(minutes)
      }
      newDate.setHours(hour)
      dispatch(SettingActions.setBaseDate(newDate))
    },
    [dispatch]
  )

  const acceptTerms = useCallback(() => {
    dispatch(SettingActions.acceptTerms())
  }, [dispatch])

  const setHideGuestUsers = useCallback(
    (value: boolean) => {
      dispatch(FilterActions.setHideGuests(value))
    },
    [dispatch]
  )

  const setShowOnlineOnly = useCallback(
    (value: boolean) => {
      dispatch(FilterActions.setOnlineOnly(value))
    },
    [dispatch]
  )

  const setShowDifference = useCallback(
    (value: boolean) => {
      dispatch(SettingActions.setShowDifference(value))
    },
    [dispatch]
  )

  return {
    ...settings,
    baseDate: new Date(baseDate),
    acceptTerms,
    followLiveTime,
    setHour,
    hideGuestUsers: hideGuests,
    showOnlineOnly: onlineOnly,
    setHideGuestUsers,
    setShowDifference,
    setShowOnlineOnly
  }
}
