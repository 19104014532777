import { useTeam } from '../../../../hooks'
import styles from './company-info.module.css'
import { usePresenceLoading } from './use-presence-loading'
import { Caption2, Title2 } from '../../../shared'
import { FilterDetails } from './filter-details/filter-details'
import { Tooltip } from '@chakra-ui/react'
import { QuestionIcon } from '@chakra-ui/icons'
import { useView } from '../../../../hooks/use-view'
import { ViewMode } from '../../../../types'
import { useSoftFilter } from '../../../../hooks/use-soft-filter'
import { Config } from '../../../../config'
import { BestMeetingTimeIndicator } from './best-meeting-time-indicator'

const PresenceLoading = () => {
  const { isLoading: presenceLoading } = usePresenceLoading()

  return (
    (presenceLoading && (
      <Title2 color="secondary" className={`animate-flicker`} style={{marginLeft: '10px'}}>
        Fetching user presence...
      </Title2>
    )) ||
    null
  )
}

const TeamInformation = () => {
  const { members } = useSoftFilter()

  return (
    <div style={{ marginLeft: '6px', display: 'inline-block' }}>
      <Title2 color="secondary">({members.length} teammates) </Title2>      
    </div>
  )
}

const TimelineTooltip = () => {
  const { view } = useView()

  if (view !== ViewMode.Timeline) {
    return null
  }

  return (
    <div style={{ marginLeft: '6px', display: 'inline-block', color: '#00C49B', marginBottom: '4px', fontSize: '14px', position: 'relative' }}>
    
      <Tooltip
        hasArrow
        label={
          <Caption2 color="white" fontWeight={600} padding={2}>
            The hours colored in green in are extracted from Slack
            notifications schedule. <br /> <br />
            Configure them in Slack {'>'} Preferences {'>'}{' '}
            Notifications.<br /> <br />
            ⚠️ Due to Slack API limitations, the notifications schedule for Custom days is not fully available. We are building our own solution to solve this issue.
          </Caption2>
        }
        size="lg"
        placement="right"
        fontSize="md">
        <QuestionIcon />
      </Tooltip>
    </div>
  )
}

const PresenceTooltip = () => {
  const { members } = useSoftFilter()
  const { view } = useView()

  if (view === ViewMode.Timeline || view === ViewMode.TimezoneGroups) {
    return null
  }

  if (members.length < Config.fetchPresenceMembersLimit) {
    return null
  }

  return (
    <div
    style={{ marginLeft: '6px', display: 'inline-block', color: '#ccc', marginBottom: '4px', fontSize: '14px', position: 'relative' }}>
      <Tooltip
        label={
          <Caption2 color="white" fontWeight={600}>
            The user presence (little dot next to user avatar) will not be fetched
            for this group of teammates. It will be available on groups of less than{' '}
            {Config.fetchPresenceMembersLimit} teammates.
          </Caption2>
        }
        size="lg"
        placement="right"
        fontSize="md">
        <QuestionIcon />
      </Tooltip>
    </div>
  )
}

export const InfoBar = () => {
  const { name } = useTeam()
  return (
    <div className={styles.teamInfoContainer}>
      <div className="flex" style={{ width: '100%'}}>
        <div style={{ flex: '1', alignSelf: 'center' }}>
          <div style={{ display: 'inline-block' }}>
            <Title2 color="accent" fontWeight={500}>
              {name}
            </Title2>
            <FilterDetails />
          </div>
          <TeamInformation />
          <PresenceTooltip />
          <TimelineTooltip />
          <PresenceLoading />
        </div>
        <div style={{ display: 'flex' }}>
          
          <BestMeetingTimeIndicator />          
        </div>        
      </div>
    </div>
  )
}
