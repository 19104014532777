import styles from '../user.module.css'
import { UserCard } from '../../user-card'
import { RefObject, useState } from 'react'
import { TeamMember } from '../../../../../types'
import { PresenceIndicator } from '../../presence/presence-indicator'
import { StatusEmoji } from '../../status/status-emoji/status-emoji'

export const CompactUser = ({
  userName,
  user
}: {
  userName: RefObject<HTMLDivElement>
  user: TeamMember
}) => {
  const [showDetails, setShowDetails] = useState(false)

  return (
    <div
      ref={userName}
      className={styles.compactUserBox}
      onMouseEnter={() => setShowDetails(true)}
      onMouseLeave={() => setShowDetails(false)}>
      <PresenceIndicator userId={user.id} isCompact={true} />
      <StatusEmoji userId={user.id} isCompact={true} />
      <div className={styles.compactUserName}>{user.profile.real_name}</div>
      {showDetails && <UserCard user={user} />}
    </div>
  )
}
