import { combineReducers } from '@reduxjs/toolkit'
import { teamReducer } from './slices/team'
import { settingsReducer } from './slices/settings'
import { presenceReducer } from './slices/presence'
import { filterReducer } from './slices/filters'

export const rootReducer = combineReducers({
  team: teamReducer,
  settings: settingsReducer,
  presence: presenceReducer,
  filter: filterReducer
})
