export interface SlackUser {
  ok: boolean
  url: string
  team: string
  user: string
  team_id: string
  user_id: string
  is_enterprise_install: boolean
}

interface UserProfile {
  status_emoji: string
  real_name: string
  huddle_state_expiration_ts: number
  title: string
  status_text_canonical: string
  huddle_state: string
  skype: string
  image_original: string
  real_name_normalized: string
  avatar_hash: string
  first_name: string
  email: string
  status_emoji_display_info: any[]
  guest_invited_by?: string
  last_name: string
  image_48: string
  image_512: string
  team: string
  display_name: string
  status_expiration: number
  phone: string
  status_text: string
  is_custom_image: boolean
  fields: null
  display_name_normalized: string
}

export interface TeamMember {
  id: string
  team_id: string
  name: string
  deleted: boolean
  color: string
  real_name: string
  tz: string
  tz_label: string
  tz_offset: number
  profile: UserProfile
  is_admin: boolean
  is_owner: boolean
  is_primary_owner: boolean
  is_restricted: boolean // Guest user
  is_ultra_restricted: boolean
  is_bot: boolean
  is_app_user: boolean
  updated: number
  is_email_confirmed: boolean
  who_can_share_contact_card: string
  presence?: Presence
  is_workflow_bot?: boolean
}

export interface Conversation {
  id: string
  name: string
  is_channel: boolean
  is_group: boolean
  is_im: boolean
  is_mpim: boolean
  is_private: boolean
  created: number
  is_archived: boolean
  is_general: boolean
  unlinked: number
  name_normalized: string
  is_shared: boolean
  is_org_shared: boolean
  is_pending_ext_shared: boolean
  pending_shared: any[]
  parent_conversation: null
  creator: string
  is_ext_shared: boolean
  shared_team_ids: string[]
  pending_connected_team_ids: any[]
  is_member: boolean
  topic: { last_set: number; creator: string; value: string }
  purpose: { last_set: number; creator: string; value: string }
  previous_names: any[]
  num_members: number
}

export interface UserGroup {
  id: string
  team_id: string
  is_usergroup: boolean
  is_subteam: boolean
  name: string
  description: string
  handle: string
  is_external: boolean
  date_create: number
  date_update: number
  date_delete: number
  auto_type: null
  auto_provision: boolean
  enterprise_subteam_id: string
  created_by: string
  updated_by: string
  deleted_by: null
  prefs: { channels: any[]; groups: any[] }
  user_count: number
  channel_count: number
  users?: string[]
}

export interface TeamMetadata {
  id: string
  name: string
  url: string
}

export interface MembersByZone {
  timezone: string
  label: string
  continent: string
  offset: number
  members: TeamMember[]
  priority: number
  difference?: number
}

export type FilterCategory = 'channel' | 'group' | 'userGroup'

export interface FilterOption {
  type: FilterCategory
  value: string
}

export type Filter = FilterOption

export type SlackResponse<K extends string, T> = {
  ok: boolean
  response_metadata?: {
    next_cursor: string
  }
} & Record<K, T[]>

export type Presence = 'active' | 'away' | 'loading'

export enum ViewMode {
  Timeline = 'timeline',
  Timezone = 'timezone',
  TimezoneCompact = 'timezoneCompact',
  TimezoneGroups = 'grouped'
}

export interface UpdateWebRequestResponse {
  status: string
  trialLeftDays?: number
  inviteSent: string
}
