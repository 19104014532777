import { extendTheme } from '@chakra-ui/react'

export const teamTimezoneTheme = extendTheme({
  fonts: {
    heading: 'Open Sans, sans-serif',
    body: 'Open Sans, sans-serif'
  },
  textStyles: {
    header1: {
      fontSize: '19px',
      fontWeight: '500',
      lineHeight: 'normal',
      textSizeAdjust: 'auto'
    },
    title1: {
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: 'normal',
      textSizeAdjust: 'auto'
    },
    title2: {
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: 'normal',
      textSizeAdjust: 'auto'
    },
    caption1: {
      fontSize: '10px',
      fontWeight: '400',
      lineHeight: 'short',
      textSizeAdjust: 'auto'
    },
    caption2: {
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: 'normal',
      textSizeAdjust: 'auto'
    }
  },
  semanticTokens: {
    colors: {
      primary: '#000',
      secondary: '#bbbbbb',
      accent: '#02C59B',
      gray: 'gray',
      error: '#FD6268',
      text: {
        default: '#000',
        _dark: 'gray.50'
      }
    }
  }
})
