import { Text } from '@chakra-ui/react'
import { TextProps } from '@chakra-ui/layout/dist/declarations/src/text'
import { ReactNode } from 'react'

type FontColor = 'primary' | 'secondary' | 'accent' | 'gray' | 'white'
type CustomProperties = {
  children: ReactNode | undefined
  color?: FontColor
} & TextProps

export const Header = ({ children, ...properties }: CustomProperties) => (
  <Text color={'primary'} {...properties} textStyle="header1">
    {children}
  </Text>
)

export const Title1 = ({ children, ...properties }: CustomProperties) => (
  <Text color={'primary'} as="span" {...properties} textStyle="title1">
    {children}
  </Text>
)

export const Title2 = ({ children, ...properties }: CustomProperties) => (
  <Text color={'primary'} as="span" {...properties} textStyle="title2">
    {children}
  </Text>
)

export const Caption1 = ({ children, ...properties }: CustomProperties) => (
  <Text color={'primary'} as="div" {...properties} textStyle="caption1">
    {children}
  </Text>
)

export const Caption2 = ({ children, ...properties }: CustomProperties) => (
  <Text color={'primary'} as="div" {...properties} textStyle="caption2">
    {children}
  </Text>
)
