import styles from './current-time.module.css'
import { useIntl } from 'react-intl'
import { useEffect, useMemo, useState } from 'react'
import logo from '../../../../../assets/img/logo-1.png'
import { useSettings } from '../../../../../hooks'
import { TimeSlider } from './time-slider'
import { Header } from '../../../../shared'
import { mergeStyles } from '../../../../../utils/merge-styles'
import { useDispatch, useSelector } from '../../../../../store'
import { useInterval } from '@chakra-ui/react'
import { SettingActions } from '../../../../../store/slices/settings'
import { Config } from '../../../../../config'

import { CoachMark } from '../shared/coach-mark/coach-mark'

const getCurrentTime = (formatTime: (x: number) => string, y: Date) =>
  formatTime(y.valueOf())

export const CurrentTime = () => {
  const { formatTime } = useIntl()
  const { baseDate } = useSettings()
  const [time, setTime] = useState(getCurrentTime(formatTime, baseDate))
  const [isOpen, setOpen] = useState(false)
  const useRealDate = useSelector((state) => state.settings.followRealDate)
  const dispatch = useDispatch()

  useEffect(() => {
    setTime(formatTime(baseDate, Config.timeFormat))
  }, [formatTime, baseDate])

  useInterval(
    () => {
      dispatch(SettingActions.setBaseDate(new Date()))
    },
    useRealDate ? 30_000 : null
  )

  return useMemo(
    () => (
      <div className={styles.groupContainer}>
        <img alt="clock icon" src={logo} className={styles.logo} />
        <div className={mergeStyles('app-title', styles.appTitle)}>
          <Header>{time}</Header>
        </div>
        <TimeSlider isOpen={isOpen} />
        <i
          className={mergeStyles(
            `material-icons`,
            styles.greyIcon,
            isOpen ? '' : styles.greyIconClosed
          )}
          style={{ userSelect: 'none' }}
          onClick={() => setOpen(!isOpen)}>
          keyboard_arrow_left
        </i>
        <CoachMark
          id="time-slider"
          title="Preview different times"
          description="Click the arrow to open the time slider. Drag the slider to shift
            between times and see the difference."
          style={{ position: 'relative', left: '-32px', top: '10px' }}
        />
      </div>
    ),
    [isOpen, time]
  )
}
