import { FilterCategory } from '../../../../../../types'
import { Title2 } from '../../../../../shared'

export const FilterIcon = ({ category }: { category: FilterCategory }) => {
  if (category === 'channel') {
    return <Title2 as={'span'} style={{top: '-2px', left: '3px', position: 'relative'}}>#</Title2>
  }
  if (category === 'group') {
    return (
      <i
        className="material-icons"
        style={{ fontSize: '15px', color: '#333', top: '-3px', left: '3px', position: 'relative' }}>
        lock
      </i>
    )
  }
  return (
    <i
      className="material-icons"
      style={{ fontSize: '15px', color: '#333', top: '-2px', left: '1px', position: 'relative' }}>
      group
    </i>
  )
}
