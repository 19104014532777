import { QuestionIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  CloseButton,
  Collapse,
  useDisclosure
} from '@chakra-ui/react'
import { Tooltip } from '@chakra-ui/tooltip'
import { useIntl } from 'react-intl'
import { Config } from '../../../../config'
import { useMeetingTime } from '../../../../hooks'
import { useSelectedTime } from '../../../../hooks/use-selected-time'
import { Caption2 } from '../../../shared'
import { ScheduleButton } from '../../../shared/schedule-button/schedule-button'
import { CoachMark } from '../toolbar/shared/coach-mark/coach-mark'

export const BestMeetingTimeIndicator = () => {
  const { isOpen, onToggle } = useDisclosure()

  return (
    <div style={{ display: 'block', minWidth: '300px' }}>
      <Collapse in={!isOpen} animateOpacity>
        <Box color={'white'} p={2} display="flex" justifyContent="end">
          <Button size="xs" padding="15px" color="#666" onClick={onToggle}>
            Suggested meeting time
          </Button>
          <CoachMark
            id="suggested-meeting-time"
            title="View suggested meeting times"
            description="Click the button to view suggested meeting times with best availability."
            style={{
              position: 'absolute',
              marginRight: '157px',
              marginTop: '17px'
            }}
          />
        </Box>
      </Collapse>
      <Collapse in={isOpen} animateOpacity>
        <Box
          height="60px"
          color="white"
          bg="#FFF"
          borderRadius="5px"
          display="flex"
          justifyContent="end">
          <div>
            <Content onClose={onToggle} />
          </div>
        </Box>
      </Collapse>
    </div>
  )
}

const Content = ({ onClose }: { onClose: () => void }) => {
  const { hoursWithMaxAvailability, maxAvailabilityPercent } = useMeetingTime()
  const { setSelectedTime } = useSelectedTime()
  const { formatTime } = useIntl()

  if (!maxAvailabilityPercent) {
    return null
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'end',
        position: 'relative',
        fontSize: '13px',
        justifyContent: 'space-between',
        alignSelf: 'center',
        marginLeft: '20px'
      }}>
      <div style={{ display: 'flex' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start'
          }}>
          <div style={{ color: '#666' }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IndicatorTooltip />
              <div style={{ marginLeft: '5px' }}>Suggested meeting time</div>
              <CloseButton color={'gray.500'} size="sm" onClick={onClose} />
            </div>
            <div
              style={{
                display: 'flex',
                paddingTop: '3px',
                justifyContent: 'flex-end'
              }}>
              {hoursWithMaxAvailability.map((o, index) => (
                <div
                  key={index}
                  style={{
                    marginRight: '5px',
                  }}
                  onMouseOver={() => {
                    console.log('mouse over')
                    setSelectedTime(o.hour.toString())
                  }}
                  onMouseOut={() => {
                    console.log('mouseOut')
                    setSelectedTime('')
                  }}>                  
                    { /*<ScheduleButton time={formatTime(o.hour, { ...Config.timeFormat })}/> */}
                    <ScheduleButton initialDate={o.hour} label={formatTime(o.hour, { ...Config.timeFormat })} />
                  
                </div>
              ))}{' '}
              <div
                style={{
                  paddingLeft: '5px',
                  paddingTop: '2px',
                  color: '#00C49B'
                }}>
                (
                <span style={{ color: '#00C49B' }}>
                  {Math.round(maxAvailabilityPercent * 100)}%
                </span>
                &nbsp; availability)
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const IndicatorTooltip = () => {
  return (
    <div
      style={{
        textAlign: 'right',
        padding: '0px',
        verticalAlign: 'bottom',
        color: '#999',
        marginLeft: '5px',
        display: 'inline'
      }}>
      <Tooltip
        label={
          <Caption2 color="white" fontWeight={600}>
            When most people in the selected group are available.
            <br /><br />
            Calculated based on a standard working schedule (9h to 17h).
            <br />
          </Caption2>
        }
        size="lg"
        placement="left"
        padding={2}
        hasArrow
        fontSize="md">
        <QuestionIcon />
      </Tooltip>
    </div>
  )
}
