import { useFiltered } from '../../hooks/use-filtered'
import { useRouter } from '../../hooks/use-router'
import { useEffect, useMemo } from 'react'
import { ViewMode } from '../../types'
import { Config } from '../../config'
import { useView } from '../../hooks/use-view'
import { useSelector } from '../../store'

const views = [ViewMode.Timeline, ViewMode.Timezone, ViewMode.TimezoneCompact]

export const getDesiredView = (members: number) => { 

  if(isSlackCommandView()) {
    return (
      views.find((view) => members <= Config.desiredSlackCommandViewLimits[view]) ??
      ViewMode.TimezoneGroups
    )
  }

  return (
    views.find((view) => members <= Config.desiredViewLimits[view]) ??
    ViewMode.TimezoneGroups
  )
}

const isSlackCommandView = () => localStorage.getItem('command') ? true : false

export const useDesiredView = () => {
  const { members, isLoading } = useFiltered()
  const { changeView } = useRouter()
  const { view } = useView()
  const changed = useSelector((state) => state.filter.changed)

  const desiredView = useMemo(() => {
    if (members.length === 0 || isLoading) {
      return
    }

    if(isSlackCommandView()) {
      return getDesiredView(members.length)
    }

    if (
      !changed &&
      (!Config.viewLimits[view] || members.length <= Config.viewLimits[view])
    ) {
      return view
    }

    return getDesiredView(members.length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [members.length, changed])

  useEffect(() => {
    if (desiredView && desiredView !== view) {
      changeView(desiredView)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [desiredView])
}

