import { ViewOptions } from './view-options/view-options'
import { Dropdown } from '../shared/dropdown'
import { useActiveUser } from './use-active-user'

export const UserLinks = () => {
  const activeUser = useActiveUser()

  return (
    <Dropdown
      icon={(properties: any) => (
        <i className="material-icons" {...properties}>
          person
        </i>
      )}
      children={<ViewOptions />}
      text={activeUser?.profile.display_name || activeUser?.profile.real_name}
      width={'200px'}
    />
  )
}
