import { useParams } from 'react-router'
import { useRouter } from './use-router'
import { ViewMode } from '../types'

export const useView = () => {
  const { view } = useParams()
  const { changeView } = useRouter()
  const setView = (newView: ViewMode) => changeView(newView)

  return { view: view as ViewMode, setView }
}
