import { Input, InputGroup } from '@chakra-ui/react'
import { FC } from 'react'
import { useTextFilter } from '../../../../../../hooks'

export const TextSearch: FC = () => {
  const { textFilter, setTextFilter } = useTextFilter()

  return (
    <InputGroup size="md">
      <Input
        style={{fontSize: '13px'}}
        placeholder="Name/title search"
        value={textFilter}
        onChange={({ target: { value } }) => setTextFilter(value)}
      />
    </InputGroup>
  )
}
