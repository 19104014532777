import styles from '../header.module.css'
import { CurrentTime } from './current-time/current-time'
import { Filters } from './filters'
import { useBreakpoints } from '../../../../hooks'
import { mergeStyles } from '../../../../utils/merge-styles'

export const ToolBar = () => {
  const { isMobile } = useBreakpoints()
  return (
    <div
      className={mergeStyles(styles.container, isMobile ? styles.mobile : '')}>
      <CurrentTime />
      <Filters />
    </div>
  )
}
