import { useTeam } from '../../../../../../../hooks'
import { StandardFilter } from './common'

export const GroupFilter = () => {
  const { groups } = useTeam()

  return (
    <StandardFilter
      items={groups}
      type="group"
      selectProps={{ placeholder: 'Select group' }}
    />
  )
}
