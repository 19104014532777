import { FC } from 'react'
import { ChannelFilter, GroupFilter, UserGroupFilter } from './selects'
import { Dropdown } from '../shared/dropdown'

const FilterContent = () => {
  return (
    <>
      <ChannelFilter />
      <GroupFilter />
      <UserGroupFilter />
    </>
  )
}

export const SlackFilter: FC = () => {
  return (
    <Dropdown
      icon={(properties: any) => (
        <i className="material-icons" {...properties}>
          filter_list
        </i>
      )}
      children={<FilterContent />}
      text="Filter"
    />
  )
}
