import {
  Button,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from '@chakra-ui/react'
import { useSettings } from '../../hooks'
import { useCallback, useEffect } from 'react'
import styles from './terms-and-conditions.module.css'
import { SettingActions } from '../../store/slices/settings'
import { useDispatch } from '../../store'

export const TermsAndConditions = () => {
  const { isOpen, onOpen, onClose } = useDisclosure({})
  const { acceptedTerms, acceptTerms } = useSettings()
  const dispatch = useDispatch()

  useEffect(() => {
    const accepted = Boolean(localStorage.getItem('acceptedTerms'))
    if (accepted) {
      dispatch(SettingActions.acceptTerms())
    }
  }, [dispatch])

  useEffect(() => {
    if (!acceptedTerms) {
      onOpen()
    }
  }, [acceptedTerms, onOpen])

  const closeModal = useCallback(() => {
    onClose()
    acceptTerms()
  }, [acceptTerms, onClose])

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>We are happy to see you 😊</ModalHeader>
        

        <ModalCloseButton />
        <ModalBody fontSize="sm">
          <div className={styles.root}>
            <div>
              <p>
                At Team TimeZone we value your privacy, that is why we
                comply with the European Union’s General Data Protection
                Regulation (GDPR). You can find our <Link color="teal" href="https://www.teamtimezone.com/terms.html" target="_blank">Terms of Service</Link> 
                , and <Link color="teal" href="https://www.teamtimezone.com/privacypolicy.html" target="_blank">Privacy Policy</Link> in our website. By continuing to use the application, you agree to them.
              </p>
            </div>
            <div>
              <p>
                 Also, we have prepared <Link color="teal" href="https://www.teamtimezone.com/team-time-zone-pro-product-tour.html" target="_blank">Product tour</Link> to help you discover Team TimeZone.
              </p>
            </div>
            <div>
              <p>
              <b>Welcome aboard! 🚂</b>
              </p>
            </div>
          </div>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={closeModal} backgroundColor="#4ebcd3">
            Let's start
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
