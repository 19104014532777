import { useTeam } from './use-team'
import { useActiveFilter } from './use-active-filter'
import { useView } from './use-view'
import { useDispatch } from '../store'
import { useCallback, useEffect } from 'react'
import { TeamActions } from '../store/slices/team'
import { applyFilters } from '../utils/apply-filters'
import { ViewMode } from '../types'
import { FilterActions } from '../store/slices/filters'
import { makeLogger } from '../utils/debug'

const logger = makeLogger('filters:hook')

export const useFilters = () => {
  const { allMembers, userGroups } = useTeam()
  const { category, value, setLoading } = useActiveFilter()
  const { view } = useView()
  const dispatch = useDispatch()

  const doApplyFilters = useCallback(async () => {
    logger('Filter %o', { category, value })
    if (!(category && value)) {
      logger('Not going to apply filters')
      setLoading(false)
      dispatch(TeamActions.setFilteredMembers(allMembers))
      return
    }
    logger('Applying filters ')

    const [newMembers] = await applyFilters(
      allMembers,
      userGroups,
      { type: category, value },
      setLoading
    )
    dispatch(TeamActions.setFilteredMembers(newMembers))
    setLoading(false)
  }, [category, value, allMembers, userGroups, setLoading, dispatch])

  useEffect(() => {
    logger('About to apply filters')
    doApplyFilters().catch((error) => console.error(error))
  }, [doApplyFilters])

  useEffect(() => {
    if (view === ViewMode.Timeline) {
      dispatch(FilterActions.setHideGuests(false))
      dispatch(FilterActions.setOnlineOnly(false))
    }
  }, [dispatch, view])
}
