import styles from '../user-detail/user.module.css'
import { TeamMember } from '../../../../types'
import { useMemo, useState } from 'react'
import { PresenceIndicator } from '../presence/presence-indicator'
import { Status } from '../status/status'

const UserImg = ({ image, isNight }: { image: string; isNight: boolean }) => {
  return useMemo(
    () => (
      <img
        alt="User avatar"
        className={`${styles.UserAvatar} ${isNight ? styles.sleep : ''}`}
        src={image}
      />
    ),
    [image, isNight]
  )
}

const UserMetadata = ({
  userId,
  cardOpen = false
}: {
  userId: string
  cardOpen: boolean
}) => {
  return useMemo(
    () => (
      <>
        <PresenceIndicator userId={userId} isCompact={false} />
        <Status isCompact={false} userId={userId} hideStatus={cardOpen} />
      </>
    ),
    [cardOpen, userId]
  )
}

export const UserAvatar = ({
  user,
  isNight,
  cardOpen = false
}: {
  user: TeamMember
  isNight: boolean
  cardOpen: boolean
}) => {
  const [image] = useState(user.profile.image_48)
  return (
    <div style={{ position: 'relative', width: '56px' }}>
      <UserImg image={image} isNight={isNight} />
      <UserMetadata userId={user.id} cardOpen={cardOpen} />
    </div>
  )
}
