import { TeamMember } from '../../../../types'
import { createRef, useMemo } from 'react'
import { useColumnUtils } from '../../../main-document/views/timezone/column/use-column-utils'
import { CompactUser } from './displays/compact-user'
import { RegularUser } from './displays/regular-user'

export const User = ({ user }: { user: TeamMember }) => {
  const userName = createRef<HTMLDivElement>()
  const { isCompact } = useColumnUtils()
  return useMemo(
    () =>
      isCompact ? (
        <CompactUser user={user} userName={userName} />
      ) : (
        <RegularUser user={user} userName={userName} />
      ),
    [isCompact, user, userName]
  )
}
