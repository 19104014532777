import { useTeam } from '../../../../../../hooks'
import { useMemo } from 'react'

export const useTrial = () => {
  const { trialLeft } = useTeam()

  const hasTrial = useMemo(() => trialLeft >= 0, [trialLeft])

  return { hasTrial, trialLeft }
}
