import { Box } from '@chakra-ui/react'
import { TextSearch } from './text-search/text-search'
import { SlackFilter } from './slack-filter/slack-filter'
import { ViewFilter } from './view-filter/view-filter'
import { TrialIndicator } from './trail-indicator/trial-indicator'
import { UserLinks } from './user-links/user-links'
import { useBreakpoints } from '../../../../../hooks'
import { useMemo } from 'react'
import { useTrial } from './trail-indicator/use-trial'

const FilterWrapper = ({ children }: any) => {
  return (
    <Box
      p={1}
      display={{ sm: 'inline', md: 'inline-block' }}
      style={{ verticalAlign: 'top' }}
      height={'40px'}
      width={{ sm: '100%', md: '150px' }}>
      {children}
    </Box>
  )
}

export const Filters = () => {
  const { isMobile } = useBreakpoints()
  const { hasTrial } = useTrial()
  const filters = useMemo(
    () => [<TextSearch />, <SlackFilter />, <ViewFilter />, <UserLinks />],
    []
  )

  return (
    <>
      <div>
        <div
          style={{
            maxWidth: isMobile ? '100%' : '700px',
            fontSize: '13.3333px !important',
            paddingLeft: '20px',
            verticalAlign: 'middle'
          }}>
          {filters.filter(Boolean).map((filter, index) => (
            <FilterWrapper key={index}>{filter}</FilterWrapper>
          ))}
          {hasTrial && (
            <Box
              p={1}
              display={{ sm: 'block', md: 'inline-block' }}
              style={{ verticalAlign: 'middle' }}
              width={'auto'}>
              <TrialIndicator />
            </Box>
          )}
        </div>
      </div>
    </>
  )
}
