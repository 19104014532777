import { useTeam } from '../../hooks/use-team'
import { useDispatch, useSelector } from '../../store'
import { useEffect, useMemo } from 'react'
import { TeamMember } from '../../types'
import { SlackService } from '../../services/slack.service'
import { PresenceActions } from '../../store/slices/presence'
import { useInterval } from '@chakra-ui/react'
import { makeLogger } from '../../utils/debug'
import { Config } from '../../config'

const logger = makeLogger(`presence:hook`)

export const usePresence = () => {
  const { members } = useTeam()
  const dispatch = useDispatch()
  const { status } = useSelector((state) => state.presence)
  const loading = useSelector((state) => state.presence.loading)

  const memberList = useMemo(
    () =>
      [...members].sort((a, b) => {
        if (a.tz_offset > b.tz_offset) {
          return 1
        } else if (a.tz_offset === b.tz_offset && a.name > b.name) {
          return 1
        }
        return -1
      }),
    [members]
  )

  const loadSinglePresence = async (user: TeamMember, force = false) => {
    if (!status[user.id] || force) {
      logger('Loading presence data for %s', user.id)
      try {
        dispatch(PresenceActions.setPresenceLoading(true))
        const { presence } = await SlackService.getStatus(user.id)
        await new Promise((resolve) => setTimeout(resolve, Config.fetchPresenceDelay))
        logger(`Presence data loaded for [${user.id}]:[${presence}]`)
        dispatch(PresenceActions.setPresence(user.id, presence))
      } catch (error) {
        console.error(`Failed to load presence data for user`, error)
      }
    } else {
      logger('Dont need to load presence data for %s', user.id)
    }
  }

  const applyPresenceData = async (members: TeamMember[], force = false) => {   
    if(members.length < Config.fetchPresenceMembersLimit) {
      for (const user of members) {
        await loadSinglePresence(user, force)
      }
      logger('presence data loaded')    
      dispatch(PresenceActions.setPresenceLoading(false))
    }
  }

  
  useEffect(() => {
    logger('Requesting presence data for %o', memberList)
    applyPresenceData(memberList).catch((error) => console.error(error))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberList])
  

  useInterval(
    () => {
      logger('Reloading presence for filtered members')
      applyPresenceData(memberList, true).catch((error) => console.error(error))
    },
    !loading ? 120_000 : null
  )
}
