import { useView } from '../../hooks/use-view'
import { useActiveFilter } from '../../hooks/use-active-filter'
import { useFilters } from '../../hooks/use-filters'
import { usePresence } from './use-presence'
import { lazy, Suspense } from 'react'
import { Loader } from '../loader/loader'
import { ViewMode } from '../../types'
import { Header } from './header'
import { useTeamData } from './use-team-data'
import { useTeam } from '../../hooks'
import { getDesiredView, useDesiredView } from './use-desired-view'
import { FeedbackForm } from './feedback-form'


const TimelineView = lazy(() => import('./views/timeline'))
const TimezoneView = lazy(() => import('./views/timezone'))

const DocumentContent = () => {
  const { view, setView } = useView()
  const { members } = useTeam()

  if (members.length === 0) {
    return <Loader />
  }

  if (members.length > 0 && !view) {
    setView(getDesiredView(members.length))
    return <Loader />
  }

  return view === ViewMode.Timeline ? <TimelineView /> : <TimezoneView />
}

const DataLoader = () => {
  useDesiredView()
  useFilters()
  usePresence()
  useTeamData()
  return null
}

const MainDocument = () => {
  const { loading } = useActiveFilter()

  return (
    <>
      <div>
        <Header />
      </div>
      {!loading && (
        <div style={{ flex: '1', overflowY: 'auto' }}>
          <div id="view-container">
            <Suspense fallback={<Loader />}>
              <DocumentContent />
            </Suspense>
          </div>
        </div>
      )}
      {loading && <Loader />}
      <DataLoader />
      <FeedbackForm />
    </>
  )
}

export default MainDocument
