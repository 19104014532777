import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Container,
  Radio,
  RadioGroup,
  Stack,
  useDisclosure
} from '@chakra-ui/react'
import { ViewMode } from '../../../../../../../../types'
import { useView } from '../../../../../../../../hooks/use-view'
import { Title2 } from '../../../../../../../shared'
import { useFiltered } from '../../../../../../../../hooks'
import { RefObject, useCallback, useRef } from 'react'
import { Config } from '../../../../../../../../config'

export const SelectView = () => {
  const { view, setView } = useView()
  const { members } = useFiltered()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelReference = useRef() as RefObject<HTMLButtonElement>
  const onSetView = useCallback(
    (view: ViewMode) => {
      if (members.length > Config.viewLimits[view]) {
        onOpen()
      } else {
        setView(view)
      }
    },
    [members.length, onOpen, setView]
  )

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelReference}
        onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Oops! Too many teammates for this view
            </AlertDialogHeader>

            <AlertDialogBody>
              <p>
                It seems you got a big group of people here ({members.length}). We are not able to handle that many users for this view yet.
                <br />
                <br />
                Please apply a filter to display less teammates or switch to another view.
                <br />
                <br />
                These are the teammates count limits for each view: <br /><br />
                - Timeline: {Config.viewLimits[ViewMode.Timeline]} people maximum<br />
                - Timezone: {Config.viewLimits[ViewMode.Timezone]} people maximum<br />
                - Timezone compact: {Config.viewLimits[ViewMode.TimezoneCompact]} people maximum<br />
                - Timezone grouped: No Limit
              </p>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelReference} onClick={onClose}>
                OK
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <RadioGroup onChange={(view: ViewMode) => onSetView(view)} value={view}>
        <Stack direction="column" spacing={3}>
          <Container>
            <Radio size="lg" colorScheme="blue" value={ViewMode.Timeline}>
              <Title2>Timeline View</Title2>
            </Radio>
          </Container>
          <Container>
            <Radio size="lg" colorScheme="blue" value={ViewMode.Timezone}>
              <Title2>Timezone View</Title2>
            </Radio>
          </Container>
          <Container>
            <Radio
              size="lg"
              colorScheme="blue"
              value={ViewMode.TimezoneCompact}>
              <Title2>Timezone Compact View</Title2>
            </Radio>
          </Container>
          <Container>
            <Radio size="lg" colorScheme="blue" value={ViewMode.TimezoneGroups}>
              <Title2>Timezone Grouped View</Title2>
            </Radio>
          </Container>
        </Stack>
      </RadioGroup>
    </>
  )
}
