import { mergeStyles } from '../../../../../utils/merge-styles'
import styles from '../status.module.css'

import { useStatus } from '../../use-status'

export const StatusEmoji = ({
  isCompact,
  userId
}: {
  isCompact: boolean
  userId: string
}) => {

  const { status } = useStatus(userId)

  if(status) {
    return (    
      <div
        className={mergeStyles(
          isCompact ? styles.compactStatusCenter : styles.statusCenter
        )}>
        {status}
      </div>
    )
  }
  
  return <></>
}
