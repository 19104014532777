import { FC } from 'react'
import Select from 'react-select'
import { FilterCategory } from '../../../../../../../types'
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager'
import { useCategoryFilter } from '../../../../../../../hooks'

const itemsToOptions = (items: { id: string; name: string }[]) =>
  items.map((it) => ({ value: it.id, label: it.name }))

export const StandardFilter: FC<{
  items: { id: string; name: string }[]
  type: FilterCategory
  selectProps: StateManagerProps
}> = ({ items, type, selectProps }) => {
  const { value, setFilter } = useCategoryFilter()

  const options = itemsToOptions(items)

  return (
    <Select
      isClearable
      isSearchable
      options={options}
      placeholder="Select #channel"
      value={options.find((x) => x.value === value) ?? ''}
      onChange={(x: any) => setFilter(type, x?.value ?? '')}
      {...selectProps}
    />
  )
}
