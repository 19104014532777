import {
  useDisclosure,
  Popover,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
  PopoverBody,
  Button
} from '@chakra-ui/react'
import { useState } from 'react'
import { HintDot } from './hint-dot'
import { PopoverTrigger as OrigPopoverTrigger } from '@chakra-ui/react'

const PopoverTrigger: React.FC<{ children: React.ReactNode }> =
  OrigPopoverTrigger


type CoachMarkProperties = {
  id: string
  title: string
  description: string
  style: React.CSSProperties
}

export const CoachMark = ({ id, title, description, style }: CoachMarkProperties) => {
  const { isOpen, onToggle, onClose } = useDisclosure()
  const [isCoachMarkVisible, setIsCoachMarkVisible] = useState(
    getCoachMarkVisibilityFromLocalStorage(id)
  )

  const onPopoverClose = () => {
    onClose()
    setIsCoachMarkVisible(false)
    setCoachMarkVisibilityToLocalStorage(id, false)
  }

  if (!isCoachMarkVisible) {
    return null
  }

  //Render popover with blue border



  return (
    <Popover      
      colorScheme={'facebook'}
      placement="bottom-end"
      returnFocusOnClose={false}
      isOpen={isOpen}
      onClose={onPopoverClose}>
      <PopoverTrigger>
        <div
          style={style}
          onClick={onToggle}>
          <HintDot />
        </div>
      </PopoverTrigger>
      <PopoverContent borderColor="blackAlpha.300">
        <PopoverArrow />
        <PopoverHeader fontWeight={'bold'} fontSize={'sm'} color="blackAlpha.800">
          {title}
        </PopoverHeader>
        <PopoverBody fontSize={'sm'} color="#333">
          <p style={{ marginBottom: '20px', color: '#333' }}>{description}</p>

          <Button onClick={onPopoverClose} size="xs">
            Got it
          </Button>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

const getCoachMarkVisibilityFromLocalStorage = (id: string): boolean => {
    const localStorageValue = localStorage.getItem(`coach-mark-${id}`)
    if (localStorageValue === null) {
      return true
    }
    return localStorageValue === 'true'
  }
  
  const setCoachMarkVisibilityToLocalStorage = (id: string, value: boolean) => {
    localStorage.setItem(`coach-mark-${id}`, value.toString())
  }