import { useTeam } from '../../../hooks/use-team'
import { useEffect, useState } from 'react'
import { EmojiConvertor } from 'emoji-js'

const emoji = new EmojiConvertor()
export const useStatus = (userId: string) => {
  const { members } = useTeam()
  const [status, setStatus] = useState('')
  const [statusText, setStatusText] = useState('')

  useEffect(() => {
    const member = members.find((x) => x.id === userId)
    if (!member) {
      return
    }

    //if member does not exist, or profile does not exist or status emoji does not exist, return
    if (!member || !member.profile || !member.profile.status_emoji) {
      return
    }



    const statusEmoji = member.profile.status_emoji
    const icon = emoji?.replace_colons(statusEmoji)
    setStatus(icon.length <= 3 ? icon : '')
    setStatusText(member.profile.status_text)
  }, [members, userId])

  return { status, statusText }
}
