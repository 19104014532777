import { createGlobalState } from 'react-hooks-global-state'

const initialState = { selectedTime: '' }
const { useGlobalState } = createGlobalState(initialState)

export const useSelectedTime = () => {
  const [selectedTime, setSelectedTime] = useGlobalState('selectedTime')

  return { selectedTime, setSelectedTime }
}
