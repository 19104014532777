import { useContext, useMemo } from 'react'
import { AuthenticationContext } from '../../../../../../context/authentication.context'
import { useTeam } from '../../../../../../hooks'

export const useActiveUser = () => {
  const { user } = useContext(AuthenticationContext)
  const { allMembers } = useTeam()

  return useMemo(
    () => (user ? allMembers.find((x) => x.id === user!.user_id) : null),
    [allMembers, user]
  )
}
