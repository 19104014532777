import React, { FC, useCallback, useEffect } from 'react'
import { useUser } from '../hooks'
import { makeLogger } from '../utils/debug'
import { SlackService } from '../services/slack.service'
import { useSearchParams } from 'react-router-dom'
import { Loader } from '../components/loader/loader'
import { TeamTimeZoneService } from '../services/team-time-zone.service'

const logger = makeLogger('authentication:guard')
export const AuthenticationGuard: FC<{ children: any }> = ({ children }) => {
  const { user } = useUser() ?? {}
  const [parameters, setParameters] = useSearchParams()

  const loadUserToken = useCallback(async () => {
    const code = parameters.get('code') as string
    const token = await TeamTimeZoneService.getSlackToken(code)
    setParameters({
      userToken: token
    })
  }, [parameters, setParameters])

  useEffect(() => {
    if (parameters.has('code')) {
      loadUserToken()
    }
  }, [loadUserToken, parameters])

  if (parameters.has('code') || parameters.has('userToken')) {
    return <Loader />
  }

  if (!user) {
    SlackService.authoriseWithSlack()
    return <Loader />
  }

  logger('User is logged in')

  return children
}
