import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { AppThunk } from '../index'
import { Conversation, MembersByZone, TeamMember, UserGroup } from '../../types'

export interface TeamState {
  allMembers: TeamMember[]
  members: TeamMember[]
  trialLeft: number
  groups: Conversation[]
  channels: Conversation[]
  userGroups: UserGroup[]
  filteredMembers: TeamMember[]
  zones: MembersByZone[]
}

const initialState: TeamState = {
  allMembers: [],
  members: [],
  trialLeft: -1,
  groups: [],
  channels: [],
  userGroups: [],
  filteredMembers: [],
  zones: []
}

export const teamsSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {
    setFilteredMembers(
      state: TeamState,
      action: PayloadAction<{
        members: TeamMember[]
      }>
    ) {
      const { members } = action.payload
      state.members = members
    },
    setTeamData(
      state: TeamState,
      action: PayloadAction<{
        members: TeamMember[]
        conversations: Conversation[]
        userGroups: UserGroup[]
      }>
    ) {
      const { members, conversations, userGroups } = action.payload
      state.allMembers = members
      state.groups = conversations.filter((x) => x.is_private && x.is_member)
      state.channels = conversations.filter((x) => !x.is_private && x.is_member)
      state.userGroups = userGroups
    },
    setTrial(
      state: TeamState,
      action: PayloadAction<{
        trial: number
      }>
    ) {
      const { trial } = action.payload
      state.trialLeft = trial
    }
  }
})

export const teamReducer = teamsSlice.reducer

const setTeamData =
  (
    members: TeamMember[],
    conversations: Conversation[],
    userGroups: UserGroup[]
  ): AppThunk =>
  (dispatch) => {
    dispatch(
      teamsSlice.actions.setTeamData({ members, conversations, userGroups })
    )
  }

const setFilteredMembers =
  (members: TeamMember[]): AppThunk =>
  (dispatch) => {
    dispatch(teamsSlice.actions.setFilteredMembers({ members }))
  }

const setTrail =
  (trial: number): AppThunk =>
  (dispatch) => {
    dispatch(teamsSlice.actions.setTrial({ trial }))
  }

export const TeamActions = {
  setTrail,
  setTeamData,
  setFilteredMembers
}
