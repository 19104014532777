import { createContext, FC, useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { AuthenticationGuard } from '../guards/authentication.guard'
import { SlackService } from '../services/slack.service'
import { SlackUser } from '../types'
import { Loader } from '../components/loader/loader'
import { makeLogger } from '../utils/debug'
import { VoidFunction } from './common'

export interface IAuthenticationContext {
  user?: SlackUser
  loading: boolean
  logout: Function
}

export const AuthenticationContext = createContext<IAuthenticationContext>({
  user: undefined,
  loading: false,
  logout: VoidFunction
})

const logger = makeLogger('authentication:provider')

export const AuthenticationProvider: FC<{ children: any }> = ({ children }) => {
  const [loading, setLoading] = useState(true)
  const [, setError] = useState<any>()
  const [user, setUser] = useState<SlackUser | undefined>()
  const [searchParameters] = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()
  logger('context rebuilt')

  const logout = useCallback(() => {
    localStorage.clear()
    window.location.replace('/')
  }, [])

  const fetchUser = useCallback(() => {
    SlackService.getUserData()
      .then((_user) => {
        setUser(_user)
        setLoading(false)
        navigate({ pathname: location.pathname })
      })
      .catch((error) => {
        setLoading(false)
        setError(error)
        console.error(error)
      })
  }, [location.pathname, navigate])

  useEffect(() => {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user))
    } else if (!loading) {
      localStorage.removeItem('user')
      localStorage.removeItem('userToken')
    }
  }, [user, loading])

  useEffect(() => {
    const queryAccessToken = searchParameters.get('userToken')
    if (queryAccessToken) {
      localStorage.setItem('userToken', queryAccessToken)
      fetchUser()
    }
  }, [fetchUser, searchParameters])

  useEffect(() => {
    const userObject = localStorage.getItem('user')
    if (userObject) {
      setUser(JSON.parse(userObject))
      setLoading(false)
    } else if (localStorage.getItem('userToken')) {
      fetchUser()
    } else {
      setLoading(false)
    }
  }, [fetchUser])

  if (loading) {
    logger('Loading user data')
    return <Loader />
  } else {
    logger('User data loaded')
  }

  return (
    <AuthenticationContext.Provider value={{ loading, user, logout }}>
      <AuthenticationGuard>{children}</AuthenticationGuard>
    </AuthenticationContext.Provider>
  )
}
