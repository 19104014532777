import { useMemo } from 'react'
import { GroupTeamMembersByTZ } from '../utils/group-team-members-by-tz'
import { useActiveFilter } from './use-active-filter'
import { useSelector } from '../store'
import { useFiltered } from './use-filtered'
import { MembersByZone } from '../types'

const addZoneDifference = (zones: MembersByZone[]) =>
  zones.map((it, index) => {
    if (index + 1 >= zones.length) {
      it.difference = 0
    } else {
      const otherTimezone = zones[index + 1].offset
      const difference = it.offset - otherTimezone
      it.difference = (difference < 0 ? difference * -1 : difference) / 60 / 60
    }
    return it
  })

export const useSoftFilter = () => {
  const { members, isLoading } = useFiltered()
  const { onlineOnly, hideGuests } = useActiveFilter()
  const status = useSelector((state) => state.presence.status)

  const fullyFilteredMembers = useMemo(
    () =>
      members.filter((it) => {
        if (hideGuests && it.is_restricted) {
          return false
        }
        const userPresence = status[it.id]
        return !(onlineOnly && userPresence !== 'active')
      }),
    [hideGuests, members, onlineOnly, status]
  )

  const zonedMembers = useMemo(() => {
    return addZoneDifference(GroupTeamMembersByTZ(fullyFilteredMembers))
  }, [fullyFilteredMembers])

  return { isLoading, members: fullyFilteredMembers, zonedMembers }
}
