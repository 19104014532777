import { Filter, TeamMember, UserGroup } from '../types'
import { SlackService } from '../services/slack.service'

export const applyFilters = async (
  members: TeamMember[],
  userGroups: UserGroup[],
  filter: Filter,
  setLoading: Function
): Promise<[TeamMember[]]> => {
  if (!filter.type) {
    return [members]
  }

  const isConversationFilter =
    filter.type === 'channel' || filter.type === 'group'

  if (isConversationFilter) {
    setLoading(true)
  }

  const conversationMembersIds: string[] = isConversationFilter
    ? await SlackService.getConversationMembers(filter.value)
    : userGroups.find((x) => x.id === filter?.value)?.users ?? []


  const conversationMembers = members.filter((it) => conversationMembersIds.includes(it.id))

  const conversationMembersIdsNotInconversationMembers = conversationMembersIds.filter((member) => !conversationMembers.map( (member) => member.id).includes(member))
  
  if(!conversationMembersIdsNotInconversationMembers?.length) {
    return [conversationMembers]
  }

  const noRegularMembers = await Promise.all(conversationMembersIdsNotInconversationMembers.map((member) => SlackService.getUserInfo(member)))

  const connectMembers = noRegularMembers.filter((x: TeamMember | null): x is TeamMember => {
    if (x === null) {
      // Handle the case where the item is null
      return false;
    }
    // Existing checks remain the same
    const isDeleted = Boolean(x.deleted);
    const isBot = Boolean(x.is_bot);
    const isSlackBot = x.id === 'USLACKBOT';
    return !(isDeleted || isBot || isSlackBot);
  });

  //merge conversationMembers and connectMembers
  for (const member of connectMembers) {
    if (member) {
      conversationMembers.push(member)
    }
  }

  return [conversationMembers]
}
