import { useCategoryFilter, useTeam } from '../../../../../hooks'
import { useMemo } from 'react'
import { Conversation, FilterCategory, UserGroup } from '../../../../../types'

type Item =
  | {
      item: Conversation | UserGroup
      category: FilterCategory
    }
  | undefined
export const useFilterItem = () => {
  const { category, value } = useCategoryFilter()
  const { channels, groups, userGroups } = useTeam()

  return useMemo<Item>(() => {
    if (!(category && value)) {
      return
    }

    const combined = [...channels, ...groups, ...userGroups]

    const item = combined.find((x) => x.id === value)
    if (item) {
      return { item, category } as Item
    }
  }, [category, value, channels, groups, userGroups])
}
