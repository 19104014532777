import { Checkbox, Container, Stack } from '@chakra-ui/react'
import { Title2 } from '../../../../../../../shared'
import { useSettings } from '../../../../../../../../hooks'
import { ViewMode } from '../../../../../../../../types'
import { useView } from '../../../../../../../../hooks/use-view'
import { useSoftFilter } from '../../../../../../../../hooks/use-soft-filter'
import { Config } from '../../../../../../../../config'

import { useEffect } from 'react'

const saveOptionsToLocalStorage = (key:any, value:any) => {
  let options = JSON.parse(localStorage.getItem('visualizationOptions') || '{}')
  options[key] = value
  localStorage.setItem('visualizationOptions', JSON.stringify(options))
}

export const AdvancedOptions = () => {
  const {
    showTimeDifference,
    setShowDifference,
    showOnlineOnly,
    hideGuestUsers,
    setShowOnlineOnly,
    setHideGuestUsers
  } = useSettings()
  const { view } = useView()
  const { members } = useSoftFilter()

  const isViewZoneDifferenceEnabled = view !== ViewMode.Timeline  

  useEffect(() => {
    const storedVisualizationOptions = localStorage.getItem('visualizationOptions')
    if (storedVisualizationOptions) {
      const options = JSON.parse(storedVisualizationOptions)
      if (typeof options.showTimeDifference === 'boolean') setShowDifference(options.showTimeDifference)
      if (typeof options.showOnlineOnly === 'boolean') setShowOnlineOnly(options.showOnlineOnly)
      if (typeof options.hideGuestUsers === 'boolean') setHideGuestUsers(options.hideGuestUsers)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showOnlineOnly, hideGuestUsers])

  

  return (
    <Stack direction="column">
      {isViewZoneDifferenceEnabled && (
        <Container mt={2} p={3} style={{ borderTop: '1px solid #cccccc' }}>
          <Checkbox
            size="lg"
            colorScheme="blue"
            isChecked={showTimeDifference}
            onChange={(event) => {
              setShowDifference(event.target.checked)
              saveOptionsToLocalStorage('showTimeDifference', event.target.checked)
            }}>
            <Title2>View Zone Difference</Title2>
          </Checkbox>
        </Container>
      )}
      {members.length < Config.fetchPresenceMembersLimit && (
        <Container mt={0} p={3} style={{ borderTop: '1px solid #cccccc' }}>
          <Checkbox
            size="lg"
            colorScheme="blue"
            isChecked={showOnlineOnly}
            onChange={(event) => {
              setShowOnlineOnly(event.target.checked)
              saveOptionsToLocalStorage('showOnlineOnly', event.target.checked)
            }}>
            <Title2>View Only Online Users</Title2>
          </Checkbox>
        </Container>
      )}
      <Container mt={0} p={3} style={{ borderTop: '1px solid #cccccc' }}>
        <Checkbox
          size="lg"
          colorScheme="blue"
          isChecked={hideGuestUsers}
          onChange={(event) => {
            setHideGuestUsers(event.target.checked)
            saveOptionsToLocalStorage('hideGuestUsers', event.target.checked)
          }}>
          <Title2>Hide Guest Users</Title2>
        </Checkbox>
      </Container>
    </Stack>
  )
}
