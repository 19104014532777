import {
  Button,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack
} from '@chakra-ui/react'
import styles from './current-time.module.css'
import { useMemo } from 'react'
import { useBaseDate } from '../../../../../hooks/use-base-date'

export const TimeSlider = ({ isOpen }: { isOpen: boolean }) => {
  const { baseDate, changeBaseDate, followLiveTime } = useBaseDate()
  const hours = useMemo(() => baseDate.getHours(), [baseDate])
  return (
    <div
      className={`${styles.changeTimeParent} ${
        isOpen ? '' : styles.changeTimeParentClosed
      }`}>
      <div className={styles.changeTimeInput}>
        <Slider
          aria-label="slider-ex-1"
          colorScheme="#fd6368"
          //backgroundColor="#fd6368"
          onChange={(v) => changeBaseDate(v)}
          value={hours}
          defaultValue={hours}
          min={0}
          step={1}
          max={23}>
            <SliderThumb boxSize={5} backgroundColor='#fd6368'>
            </SliderThumb>
          <SliderMark value={0} mt="1" ml="-2.5" fontSize="x-small">
            00:00
          </SliderMark>
          <SliderMark value={12} mt="1" ml="-2.5" fontSize="x-small">
            12:00
          </SliderMark>
          <SliderMark value={23} mt="1" ml="-2.5" fontSize="x-small">
            23:00
          </SliderMark>
          <SliderTrack>
            <SliderFilledTrack backgroundColor="#fd636866"/>
          </SliderTrack>
          
        </Slider>
      </div>
      <Button
        className={styles.changeInputButton}
        size="xs"
        mt="auto"
        mb="auto"
        background="var(--chakra-colors-blue-500)"
        _hover={{ bg: 'var(--chakra-colors-blue-600)' }}
        ml={2}
        onClick={() => followLiveTime()}>
        Now
      </Button>
    </div>
  )
}
