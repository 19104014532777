import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { AppThunk } from '../index'
import { Presence } from '../../types'

export interface PresenceState {
  loading: boolean
  status: Record<string, Presence>
}

const initialState: PresenceState = {
  loading: false,
  status: {}
}

export const presenceSlice = createSlice({
  name: 'presence',
  initialState,
  reducers: {
    setPresenceLoading(
      state: PresenceState,
      action: PayloadAction<{
        loading: boolean
      }>
    ) {
      const { loading } = action.payload
      state.loading = loading
    },
    setUserPresence(
      state: PresenceState,
      action: PayloadAction<{ userId: string; presence: Presence }>
    ) {
      const { userId, presence } = action.payload
      state.status[userId] = presence
    }
  }
})

export const presenceReducer = presenceSlice.reducer

const setPresenceLoading =
  (loading: boolean): AppThunk =>
  (dispatch) => {
    dispatch(presenceSlice.actions.setPresenceLoading({ loading }))
  }

const setPresence =
  (userId: string, presence: Presence): AppThunk =>
  (dispatch) => {
    dispatch(presenceSlice.actions.setUserPresence({ userId, presence }))
  }

export const PresenceActions = {
  setPresence,
  setPresenceLoading
}
