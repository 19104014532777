import { useSettings } from './use-settings'
import { FormatDateOptions, useIntl } from 'react-intl'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Config } from '../config'

export const useBaseDate = (timeZone?: string, _baseDate?: Date) => {
  const { baseDate: actualBaseDate, setHour, followLiveTime } = useSettings()
  const baseDate = _baseDate ?? actualBaseDate
  const { formatTime, formatDateToParts } = useIntl()
  const [timestamp, setTimestamp] = useState(baseDate.valueOf())
  const changeBaseDate = useMemo(() => setHour, [setHour])

  const offsetTime = useMemo(
    () => formatTime(timestamp, { timeZone, ...Config.timeFormat }),
    [formatTime, timeZone, timestamp]
  )
  const offsetTimeParts = useMemo(
    () =>
      formatDateToParts(timestamp, {
        timeZone,
        hour: 'numeric',
        minute: 'numeric',
        hourCycle: 'h23'
      }),
    [formatDateToParts, timeZone, timestamp]
  )
  const rootTime = useMemo(() => formatTime(timestamp, Config.timeFormat), [formatTime, timestamp])

  useEffect(() => {
    const newTimestamp = baseDate.valueOf()
    if (newTimestamp !== timestamp) {
      setTimestamp(newTimestamp)
    }
  }, [baseDate, timestamp])

  const formatBaseDateToParts = useCallback(
    (options: FormatDateOptions) => {
      return formatDateToParts(timestamp, options)
    },
    [formatDateToParts, timestamp]
  )

  return {
    baseDate,
    offsetTime,
    offsetTimeParts,
    rootTime,
    formatBaseDateToParts,
    changeBaseDate,
    followLiveTime
  }
}
