import { useTeam } from '../../../../../../../hooks'
import { StandardFilter } from './common'

export const UserGroupFilter = () => {
  const { userGroups } = useTeam()

  return (
    <StandardFilter
      items={userGroups}
      type="userGroup"
      selectProps={{ placeholder: 'Select usergroup' }}
    />
  )
}
