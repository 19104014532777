import { ViewIcon } from '@chakra-ui/icons'
import { ViewOptions } from './view-options/view-options'
import { Dropdown } from '../shared/dropdown'

export const ViewFilter = () => {
  return (
    <Dropdown
      icon={(properties: any) => <ViewIcon {...properties} />}
      children={<ViewOptions />}
      text="View"
    />
  )
}
