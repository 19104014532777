import { SlackResponse } from '../types'

export const handlePaging = async <K extends string, T>(
  request: ({ cursor }: { cursor: string }) => Promise<SlackResponse<K, T>>,
  itemKey: K
): Promise<T[]> => {
  let cursor = ''
  let items: T[] = []
  let response: SlackResponse<K, T>
  const escape = 15
  let index = 0
  do {
    response = await request({ cursor })
    const pagedItems = response[itemKey]
    items.push(...pagedItems)
    const { response_metadata } = response
    cursor =
      response_metadata && response_metadata.next_cursor
        ? response_metadata.next_cursor
        : ''
    index++
  } while (cursor.length > 0 && index < escape)

  return items
}
