import { CopyIcon, InfoIcon } from '@chakra-ui/icons'
import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'
import { useSoftFilter } from '../../../hooks/use-soft-filter'
import { useFilterItem } from '../../main-document/header/info-bar/filter-details/use-filter-item'

export const ScheduleButton = ({
  initialDate,
  label
}: {
  initialDate?: Date
  label: string
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [startDate, setStartDate] = useState(
    initialDate ? initialDate : new Date()
  )

  useEffect(() => {
    setStartDate(initialDate ? initialDate : new Date())
  }, [initialDate])

  const startDateFormatted = startDate
    .toISOString() // eslint-disable-next-line unicorn/better-regex
    .replace(/-|:|\.\d\d\d/g, '')

  //Add half an hour to the start date
  const endDate = new Date(startDate.getTime() + 30 * 60000)
  // eslint-disable-next-line unicorn/better-regex
  const endDateFormatted = endDate.toISOString().replace(/-|:|\.\d\d\d/g, '')

  const filter = useFilterItem()
  const channelName = filter?.item.name ? capitalizeFirstLetter(filter?.item.name) : 'Team'

  const { members } = useSoftFilter()
  //Get all emails from the members
  const emails = members.map((member) => member.profile.email).join(',')

  const openGoogleCalendar = () => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const eventTiltle = `${channelName} meeting`
    const eventDescription = `Meeting with ${channelName} members`

    window.open(
      `https://calendar.google.com/calendar/r/eventedit?text=${eventTiltle}&dates=${startDateFormatted}/${endDateFormatted}&details=${eventDescription}&ctz=${userTimezone}`,
      '_blank'
    )
  }

  return (
    <>
      <Button size="xs" onClick={onOpen}>
        {label}
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Schedule event for {channelName}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div style={{ display: 'flex' }}>
              <Text color="#999" width={'170px'}>
                Date and time:
              </Text>
              <DatePicker
                showTimeSelect
                dateFormat="MMMM d, HH:mm"
                timeFormat="HH:mm"
                selected={startDate}
                onChange={(date: any) => {
                  if (date) {
                    setStartDate(date)
                  }
                }}
              />
            </div>

            <div
              style={{
                margin: '30px 0px',
                backgroundColor: '#c4dbf2',
                borderRadius: '5px',
                padding: '10px',
                color: 'black'
              }}>
              <Text fontSize={14}>
                <InfoIcon /> Event title, description, duration and guests can
                be edited directly in Google Calendar.
              </Text>
            </div>

            <div
              style={{
                margin: '30px 0px',
                backgroundColor: '#c4dbf2',
                borderRadius: '5px',
                padding: '10px',
                color: 'black'
              }}>
              <Text fontSize={14}>
                <b>Handy tip:</b> Click{' '}
                <Button                  
                  size={'xs'}
                  onClick={() => navigator.clipboard.writeText(emails)}>
                  <CopyIcon style={{marginRight: '5px'}}/>
                  this button
                </Button>{' '}
                to copy all emails from this channel group to the clipboard.
                Then you can use them to add guests to the event in Google
                Calendar.
              </Text>
            </div>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={openGoogleCalendar}>
              Open in Google Calendar
            </Button>
            <Button colorScheme="gray" mr={0} onClick={onClose}>
              Discard
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

const capitalizeFirstLetter = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1)
}
