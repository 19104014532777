import styles from './loader.module.css'

export const Loader = ({ margin = '100px' }) => {
  return (
    <div style={{ margin }}>
      <div className={styles.loadingClock}></div>
      <div className={styles.loadingText}>
        Hold tight — just getting this page ready
      </div>
    </div>
  )
}
