import { useDispatch, useSelector } from '../store'
import { useCallback } from 'react'
import { FilterActions } from '../store/slices/filters'
import { useCategoryFilter } from './use-category-filter'

export const useActiveFilter = () => {
  const filters = useSelector((state) => state.filter)
  const dispatch = useDispatch()
  const { category, value } = useCategoryFilter()
  const setLoading = useCallback(
    (loading: boolean) => {
      dispatch(FilterActions.setLoading(loading))
    },
    [dispatch]
  )

  return { ...filters, setLoading, category, value }
}
