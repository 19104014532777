import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { AppThunk } from '../index'

export interface SettingsState {
  baseDate: number
  followRealDate: boolean
  acceptedTerms: boolean
  showTimeDifference: boolean
}

const initialState: SettingsState = {
  baseDate: Date.now(),
  followRealDate: true,
  acceptedTerms: Boolean(localStorage.getItem('acceptedTerms')),
  showTimeDifference: false
}

export const teamsSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setShowDifference(
      state: SettingsState,
      action: PayloadAction<{ show: boolean }>
    ) {
      state.showTimeDifference = action.payload.show
    },
    followRealDate(
      state: SettingsState,
      action: PayloadAction<{ follow: boolean }>
    ) {
      state.followRealDate = action.payload.follow
    },
    acceptTerms(state: SettingsState) {
      state.acceptedTerms = true
    },
    setBaseDate(state: SettingsState, action: PayloadAction<{ date: number }>) {
      const { date } = action.payload
      state.baseDate = date
    }
  }
})

export const settingsReducer = teamsSlice.reducer

const acceptTerms = (): AppThunk => {
  return (dispatch) => {
    localStorage.setItem('acceptedTerms', 'true')
    dispatch(teamsSlice.actions.acceptTerms())
  }
}

const setBaseDate =
  (date: Date): AppThunk =>
  (dispatch) => {
    dispatch(teamsSlice.actions.setBaseDate({ date: date.valueOf() }))
  }

const followRealDate =
  (follow: boolean): AppThunk =>
  (dispatch) => {
    dispatch(teamsSlice.actions.followRealDate({ follow }))
  }

const setShowDifference =
  (show: boolean): AppThunk =>
  (dispatch) => {
    dispatch(teamsSlice.actions.setShowDifference({ show }))
  }

export const SettingActions = {
  acceptTerms,
  setBaseDate,
  followRealDate,
  setShowDifference
}
