import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { AppThunk } from '../index'

type FilterState = {
  onlineOnly: boolean
  hideGuests: boolean
  loading: boolean
  changed: boolean
}

const initialState: FilterState = {
  onlineOnly: false,
  hideGuests: false,
  loading: false,
  changed: false
}

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setChanged(state: FilterState) {
      state.changed = true
    },
    setLoading(
      state: FilterState,
      action: PayloadAction<{ loading: boolean }>
    ) {
      state.loading = action.payload.loading
    },
    setMetaFilters(
      state: FilterState,
      action: PayloadAction<
        Partial<Pick<FilterState, 'onlineOnly' | 'hideGuests'>>
      >
    ) {
      const { onlineOnly, hideGuests } = action.payload
      state.onlineOnly = onlineOnly ?? state.onlineOnly
      state.hideGuests = hideGuests ?? state.hideGuests
    }
  }
})

export const filterReducer = filtersSlice.reducer

const setLoading =
  (loading: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(filtersSlice.actions.setLoading({ loading }))
  }

// eslint-disable-next-line unicorn/consistent-function-scoping
const setChanged = (): AppThunk => async (dispatch) => {
  dispatch(filtersSlice.actions.setChanged())
}

const setOnlineOnly =
  (onlineOnly: boolean): AppThunk =>
  (dispatch) => {
    dispatch(filtersSlice.actions.setMetaFilters({ onlineOnly }))
  }

const setHideGuests =
  (hideGuests: boolean): AppThunk =>
  (dispatch) => {
    dispatch(filtersSlice.actions.setMetaFilters({ hideGuests }))
  }

export const FilterActions = {
  setChanged,
  setLoading,
  setOnlineOnly,
  setHideGuests
}
