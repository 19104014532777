import styles from './status.module.css'
import { StatusEmoji } from './status-emoji/status-emoji'
import { useStatus } from '../use-status'
import { Caption1 } from '../../text/text'

export const Status = ({
  isCompact,
  userId,
  hideStatus = false
}: {
  isCompact: boolean
  userId: string
  hideStatus: boolean
}) => {
  const { status, statusText } = useStatus(userId)

  if (status.length === 0 && statusText.length === 0) {
    return null
  }
  return (
    <>
      {status.length > 0 &&
      (<div className={isCompact ? styles.compactStatus : styles.status}>
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
          <StatusEmoji userId={userId} isCompact={isCompact} />
        </div>
      </div>)
      }
      {!hideStatus && statusText.length > 0 && (
        <div className={styles.statusText}>
          <Caption1>{statusText}</Caption1>
        </div>
      )}
    </>
  )
}
