import { useTextFilter } from '../../../../../../../../hooks/use-text-filter'
import { TeamMember } from '../../../../../../../../types'
import { useEffect, useState } from 'react'
import { makeLogger } from '../../../../../../../../utils/debug'

const logger = makeLogger('is-filtered:hook')

export const useIsFiltered = ({ user }: { user: TeamMember }) => {
  const { textFilter } = useTextFilter()
  const [isFiltered, setIsFiltered] = useState(false)


  useEffect(() => {    
    const userName = user.real_name ? user.real_name.toLowerCase() : user.profile.first_name.toLowerCase()
    const userTitle = user.profile?.title?.toLowerCase()
    logger(`Filter changed %o`, { textFilter, userName })
    setIsFiltered(
      textFilter.length === 0 || 
        userName?.includes(textFilter.toLowerCase()) || 
        userTitle?.includes(textFilter.toLowerCase())        
    )
  }, [textFilter, user.real_name, user.profile.title, user.profile.first_name])

  return isFiltered
}
