import { createGlobalState } from 'react-hooks-global-state'

const initialState = { textFilter: '' }
const { useGlobalState } = createGlobalState(initialState)

export const useTextFilter = () => {
  const [textFilter, setTextFilter] = useGlobalState('textFilter')

  return { textFilter, setTextFilter }
}
