import { createContext, useMemo } from 'react'
import { MembersByZone, ViewMode } from '../../../../../types'
import { useView } from '../../../../../hooks/use-view'
import { useIsNight } from './use-is-night'
import { useIsActiveZone } from './use-is-active-zone'
import { useBaseDate } from '../../../../../hooks/use-base-date'

export interface IColumnContext {
  zone: MembersByZone
  view: ViewMode
  isCompact: boolean
  isNight: boolean
  isActiveZone: boolean
  offsetTime: string
}

export const ColumnContext = createContext<IColumnContext>({} as IColumnContext)

export const ColumnContextProvider = ({
  zone,
  children
}: {
  zone: MembersByZone
  children: any
}) => {
  const { view } = useView()
  const isCompact = useMemo(() => view === ViewMode.TimezoneCompact, [view])
  const isNight = useIsNight(zone.timezone)
  const isActiveZone = useIsActiveZone(zone)
  const { offsetTime } = useBaseDate(zone.timezone)

  return (
    <ColumnContext.Provider
      value={{ zone, view, isCompact, isNight, isActiveZone, offsetTime }}>
      {children}
    </ColumnContext.Provider>
  )
}
