import { useTrial } from './use-trial'
import styles from './trail-indicator.module.css'

export const TrialIndicator = () => {
  const { hasTrial, trialLeft } = useTrial()
  return (
    (hasTrial && (
      <a
        href="/dashboard.html"
        target="_blank"
        className={styles.trailIndicator}>
        <span>
          <strong>
            {trialLeft} day{trialLeft !== 1 ? 's' : ''}
          </strong>
          <br />
          left of trial
        </span>
      </a>
    )) ||
    null
  )
}
