import { Button, ButtonProps, Stack } from '@chakra-ui/react'
import { useContext } from 'react'
import { AuthenticationContext } from '../../../../../../../context/authentication.context'

const StandardButton = ({ children, onClick }: ButtonProps) => (
  <Button
    bg='var(--chakra-colors-blue-500)'
    color="white"
    _hover={{ bg: 'var(--chakra-colors-blue-600)' }}
    width="100%"
    onClick={onClick}>
    {children}
  </Button>
)

export const ViewOptions = () => {
  const { logout } = useContext(AuthenticationContext)

  return (
    <Stack>
      <a href="/dashboard.html" target="_blank">
        <StandardButton>Dashboard</StandardButton>
      </a>
      <a href="/" target="_blank">
        <StandardButton>Home</StandardButton>
      </a>
      <a
        href="mailto:support@teamtimezone.com"
        target="_blank"
        rel="noreferrer">
        <StandardButton>Support</StandardButton>
      </a>
      <StandardButton onClick={() => logout()}>Logout</StandardButton>
    </Stack>
  )
}
