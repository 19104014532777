import { useMemo } from 'react'
import { GroupTeamMembersByTZ } from '../utils/group-team-members-by-tz'
import { useActiveFilter } from './use-active-filter'
import { useTeam } from './use-team'

export const useFiltered = () => {
  const { loading } = useActiveFilter()
  const { members } = useTeam()

  const fullyFilteredMembers = useMemo(
    () =>
      [...members].sort((a, b) => {
        if (a.tz_offset > b.tz_offset) {
          return 1
        } else if (a.tz_offset === b.tz_offset && a.name > b.name) {
          return 1
        }
        return -1
      }),
    [members]
  )

  const zonedMembers = useMemo(() => {
    return GroupTeamMembersByTZ(fullyFilteredMembers)
  }, [fullyFilteredMembers])

  return { isLoading: loading, members: fullyFilteredMembers, zonedMembers }
}
