import { FormatDateOptions } from '@formatjs/intl'
import { ViewMode } from './types'

const slackStaging = {
  client_id: '109821712486.2788035253985',
  redirect_uri: 'https://slack-time-zone-staging.herokuapp.com/app/',
  scope: 'commands',
  user_scope:
    'dnd:read,channels:read,emoji:read,groups:read,mpim:read,usergroups:read,users.profile:read,users:read,users:read.email'
}

const slackProduction = {
  client_id: '109821712486.109280449812',
  redirect_uri: 'https://www.teamtimezone.com/app/',
  scope: 'commands',
  user_scope:
    'dnd:read,channels:read,emoji:read,groups:read,mpim:read,usergroups:read,users.profile:read,users:read,users:read.email'
}

export const Config = {
  slack: {
    baseUrl: 'https://slack.com/api',
    authConfig:
      window.location.hostname.includes('staging')
        ? slackStaging
        : slackProduction
  },
  analytics: {
    trackingCode: 'UA-88964204-1'
  },
  desiredViewLimits: {
    [ViewMode.Timeline]: 20,
    [ViewMode.Timezone]: 3000,
    [ViewMode.TimezoneCompact]: 5000
  } as Record<ViewMode, number>,
  desiredSlackCommandViewLimits: {
    [ViewMode.Timeline]: 20,
    [ViewMode.Timezone]: 200,
    [ViewMode.TimezoneCompact]: 400
  } as Record<ViewMode, number>,
  viewLimits: {
    [ViewMode.Timeline]: 100,
    [ViewMode.Timezone]: 7000,
    [ViewMode.TimezoneCompact]: 10_000
  } as Record<ViewMode, number>,
  fetchPresenceMembersLimit: 300,
  fetchPresenceDelay: 200, //ms
  timeFormat: {
    hour: 'numeric',
    hourCycle: 'h23',
    minute: '2-digit'
  } as FormatDateOptions
}
