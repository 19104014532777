import axios from 'axios'
import { SlackUser, UpdateWebRequestResponse } from '../types'
import { makeLogger } from '../utils/debug'

export const TTZApi = axios.create({
  baseURL: window.location.href.includes('localhost') ? 'http://localhost:8080/' : '/'
})


const logger = makeLogger('team-time-zone:service')

const updateWebRequest = async (
  totalUsersCount: number,
  userObject: SlackUser
) => {
  logger('Sending web update request %o', { totalUsersCount, userObject })
  const { data } = await TTZApi.post<UpdateWebRequestResponse>(
    '/user/updateWebRequest',
    {
      totalUsersCount,
      userObj: JSON.stringify(userObject)
    }
  )
  logger('Web request response %o', data)
  return data
}

const getSlackToken = (code: string) => {
  return TTZApi.get('app/token', {
    params: {
      code
    }
  }).then(({ data: { accessToken } }: any) => {
    return accessToken
  })
}

export const TeamTimeZoneService = {
  getSlackToken,
  updateWebRequest
}
