import { useRouter } from './use-router'
import { useParams } from 'react-router'
import { FilterCategory } from '../types'

export const useCategoryFilter = () => {
  const { category, value } = useParams()
  const { changeFilter } = useRouter()

  return {
    category: category as FilterCategory,
    value,
    setFilter: changeFilter
  }
}
