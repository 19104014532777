import { useUser } from '../../hooks/use-user'
import { useDispatch } from '../../store'
import { useTeam } from '../../hooks/use-team'
import { useCallback, useContext, useEffect } from 'react'
import { SlackUser } from '../../types'
import { TeamTimeZoneService } from '../../services/team-time-zone.service'
import { TeamActions } from '../../store/slices/team'
import { makeLogger } from '../../utils/debug'
import { SlackService } from '../../services/slack.service'
import { AuthenticationContext } from '../../context/authentication.context'

const logger = makeLogger('team:provider')

const loadMembers = SlackService.getUsers
const loadChannels = SlackService.getChannels
const loadGroups = SlackService.getUserGroups

export const useTeamData = () => {
  const user = useUser()
  const dispatch = useDispatch()
  const { members } = useTeam()
  const { logout } = useContext(AuthenticationContext)

  const updateBackend = useCallback(
    (members: number, user: SlackUser) => {
      TeamTimeZoneService.updateWebRequest(members, user).then(
        ({ trialLeftDays, status }) => {
          if(status === 'update_to_higher_plan'){
            window.location.href = 'https://www.teamtimezone.com/dashboard.html'
          }
          dispatch(TeamActions.setTrail(trialLeftDays ?? -1))
        } 
      )
    },
    [dispatch]
  )

  const refreshData = useCallback(() => {
    logger(`Refreshing slack data`)
    return Promise.all([loadMembers(), loadChannels(), loadGroups()])
      .then(([members, channels, groups]) => {
        dispatch(TeamActions.setTeamData(members, channels, groups))
        logger(`Slack data loaded %o`, { members, channels, groups })
        return [members, channels, groups]
      })
      .catch((error) => {
        console.error(error)
        logout()
        return [[], [], []]
      })
  }, [dispatch, logout])

  useEffect(() => {
    if (members.length === 0) {
      logger('Refreshing data from slack')
      refreshData().then(([members]) => {
        logger('Slack data is loaded')
        updateBackend(members.length, user)
      })
    }
  }, [members, refreshData, updateBackend, user])
}
