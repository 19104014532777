import { useMediaQuery } from '@chakra-ui/react'

export const useBreakpoints = () => {
  const [isLargerThanHD, isDisplayingInBrowser, isMobile] = useMediaQuery([
    '(min-width: 1920px)',
    '(display-mode: browser)',
    '(max-width: 970px)'
  ])

  return { isLargerThanHD, isDisplayingInBrowser, isMobile }
}
