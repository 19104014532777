import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'


const DOT_SIZE = 20
const PULSE_SIZE = 50
const COLOR = '#4EBCD3'


const pulseAnimation = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
  }
  25% {
    transform: scale(0);
    opacity: 0.1;
  }
  50% {
    transform: scale(0.1);
    opacity: 0.3;
  }
  75% {
    transform: scale(0.5);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
`

const Pulse = styled.div`
  border: 10px solid ${COLOR};
  background: transparent;
  border-radius: ${PULSE_SIZE}px;
  height: ${PULSE_SIZE}px;
  width: ${PULSE_SIZE}px;
  animation: ${pulseAnimation} 3s ease-out;
  animation-iteration-count: infinite;
  position: absolute;
  top: -${(PULSE_SIZE - DOT_SIZE) / 2}px;
  left: -${(PULSE_SIZE - DOT_SIZE) / 2}px;
  z-index: 1;
  opacity: 0;
`

const Hint = styled.a`  
  position: relative;
  background: transparent;
  width: ${DOT_SIZE}px;
  height: ${DOT_SIZE}px;
  cursor: pointer;

  &:focus {
    border-radius: 50%;
  }

  
`

const Dot = styled.div`
  box-sizing: border-box;
  width: ${DOT_SIZE}px;
  height: ${DOT_SIZE}px;
  border: ${DOT_SIZE / 4}px solid ${COLOR}99;
  border-radius: ${DOT_SIZE}px;
  background-color: ${COLOR}55;
  transition: all 0.2s ease-out;
`

interface Properties {
  dataQa?: string
  ariaLabel?: string
  pulse?: boolean
  role?: string
  tabIndex?: number
  onClick?: React.MouseEventHandler
  onKeyDown?: React.KeyboardEventHandler
}

const HintDot = React.forwardRef<HTMLAnchorElement, Properties>(
  (
    { ariaLabel, dataQa, onClick, onKeyDown, pulse = true, role, tabIndex = 0 },
    reference
  ) => (
    <Hint
      ref={reference}
      aria-label={ariaLabel}
      data-qa={dataQa}
      onClick={onClick}
      onKeyDown={onKeyDown}
      role={role}
      tabIndex={tabIndex}
    >
      {pulse && <Pulse />}
      <Dot />
    </Hint>
  )
)

HintDot.propTypes = {
  dataQa: PropTypes.string,
  ariaLabel: PropTypes.string,
  pulse: PropTypes.bool,
  role: PropTypes.string,
  tabIndex: PropTypes.number,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
}

export { HintDot }