/* eslint-disable react-hooks/exhaustive-deps */
import { generatePath, useNavigate, useParams } from 'react-router'
import { FilterCategory, ViewMode } from '../types'
import { useCallback } from 'react'
import { useDispatch } from '../store'
import { FilterActions } from '../store/slices/filters'

export const useRouter = () => {
  const { view, category, value } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const doNavigation = useCallback(
    ({
      _view,
      _category,
      _value
    }: {
      _view?: ViewMode
      _category?: FilterCategory
      _value?: string
    }) => {
      dispatch(FilterActions.setChanged())
      const path = generatePath(
        _value ?? value ? '/app/:view/:category/:value' : '/app/:view',
        {
          view: _view ?? view,
          category: _category ?? category,
          value: _value ?? value
        }
      )
      navigate(path, { replace: true })
    },
    [view, category, value]
  )

  const changeView = (_view: ViewMode) => doNavigation({ _view })
  const changeFilter = (_category: FilterCategory, _value: string) =>
    doNavigation({ _category, _value })

  return { changeView, changeFilter }
}
